import styled from "styled-components";
import React, { useState, useEffect, useRef } from "react";
import DrEvent from "../display/DrEvent";
const Page = styled.div`
  backface-visibility: hidden;
  position: absolute;
  top: 5.25%;
  left: 0;
  width: 100%;
  height: 95%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function ChallengeResult(props) {
  console.log(props.socketData);

  function highlight(text) {
    const groupNameStyle = { color: "#FF3333" };
    const playerNameStyle = { color: props.playerColor };
    text = text.replace(
      /<any>/g,
      `<span style="${Object.entries(groupNameStyle)
        .map(([key, value]) => `${key}:${value}`)
        .join(";")}" class="playerName">Anyone</span>`
    );
    text = text.replace(
      /<all>/g,
      `<span style="${Object.entries(groupNameStyle)
        .map(([key, value]) => `${key}:${value}`)
        .join(";")}" class="playerName">Everyone</span>`
    );
    return text.replace(
      /<name>/g,
      `<span style="${Object.entries(playerNameStyle)
        .map(([key, value]) => `${key}:${value}`)
        .join(";")}" class="playerName">${props.turnPlayerName}</span>`
    );
  }
  function renderVotingOptions() {
    let options = [];
    props.choices.forEach((option) => {
      options.push(
        <button
          className="choiceChalOption"
          disabled={true}
          vote-id={option}
          key={option}
          onClick={null}
          style={{
            backgroundColor:
              option === props.answer
                ? "#00FF32"
                : option === props.socketData.userInput
                ? "#FF3333"
                : "white",
            color: "var(--offBlack)",
          }}
        >
          {option}
        </button>
      );
    });
    return options;
  }
  return (
    <DrEvent
      className="challengeContainer"
      style={{ transform: props.flipped ? "rotateY(180deg)" : "" }}
    >
      <div className="chalTitleContainer">{props.title}</div>
      <div className="choiceChalPrompt">
        <p
          dangerouslySetInnerHTML={{
            __html: highlight(
              props.socketData.userInput === props.answer
                ? `Correct, <name> give out a drink.`
                : `Incorrect, ${props.socketData.penalty}`
            ),
          }}
        ></p>
      </div>
      <div className="choiceChalContent">{renderVotingOptions()}</div>
      {props.yourId === props.turnPlayer || true ? (
        <button className="splashNavBtn" onClick={props.handleClose}>
          END TURN
        </button>
      ) : null}
    </DrEvent>
  );
}

export default ChallengeResult;
