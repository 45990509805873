import React, { useEffect } from "react";

const AppStore = (props) => {
  const APKUrl =
    "https://play.google.com/store/apps/details?id=dr.android.drinkroyale";
  const appleUrl = "https://apps.apple.com/us/app/host-royale/id6569216702";

  function handleBack(e) {
    const container = document.querySelector(".appStoreContainer");
    container.style.opacity = 1;
    container.style.animation = "fadeOut .5s linear .1s 1 forwards";
    setTimeout(() => {
      props.handleBack(e);
    }, 750);
  }
  return (
    <div className="appStoreContainer">
      <div className="appStorePrompt">
        {`Only one host needs the app to start a 12-player game`}
      </div>
      <div className="appStoreAccentPrompt">{`free trial available`}</div>
      <a href={appleUrl} target="_blank" rel="noopener noreferrer">
        <img
          src="/store/appstore.png"
          alt="Download on the App Store"
          className="storeButton"
        />
      </a>
      <a href={APKUrl} target="_blank" rel="noopener noreferrer">
        <img
          src="/store/googleplay.png"
          alt="Get it on Google Play"
          className="storeButton"
        />
      </a>

      <button className="appStoreBackButton" onClick={handleBack}>
        BACK
      </button>
    </div>
  );
};

export default AppStore;
