import React, { useState, useEffect } from "react";

const NavButton = (props) => {
  const [countdown, setCountdown] = useState(props.delay);
  const [isTimeUp, setIsTimeUp] = useState(false);

  useEffect(() => {
    if (window.innerHeight > window.innerWidth) {
      const interval = setInterval(() => {
        if (countdown === 0) {
          if (window.innerHeight > window.innerWidth) {
            setIsTimeUp(true); // Mark the time as up
          }
          clearInterval(interval);
        } else {
          setCountdown((countdown) => countdown - 1);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [countdown]);

  return (
    <button
      className={`splashNavBtn`}
      onClick={props.action} //${isTimeUp ? 'pulse-focus' : ''} TODO
    >
      {props.text}
    </button>
  );
};

export default NavButton;
