import React, { useState, useEffect, useRef, useMemo } from "react";
import Splash from "./EventCard.js";
import Results from "./ResultCard.js";
import DrPage from "../display/DrPage.js";
import TurnSlider from "./TurnSlider.js";
import GameHeader from "./GameHeader.js";

import { stageInfiniteTurnData } from "./TurnHandler.js";
import DrContent from "../display/DrContent.js";

const iconPaths = [
  "game piece-0.png",
  "game piece-01.png",
  "game piece-02.png",
  "game piece-03.png",
  "game piece-04.png",
  "game piece-05.png",
  "game piece-06.png",
  "game piece-07.png",
  "game piece-08.png",
  "game piece-09.png",
  "game piece-10.png",
  "game piece-11.png",
  "portrait4x.png",
];
const playerColors = [
  "#00FFFF",
  "#FF0000",
  "#FFFF00",
  "#99FF00",
  "#339933",
  "#FF9450",
  "#990050",
  "#FF9900",
  "#9966FF",
  "#FFFFFF",
  "#FF99C3",
  "#0066CC",
];

const playerPositions = {
  1: ["a12"],
  2: ["a10", "a14"],
  3: ["a10", "a12", "a14"],
  4: ["a1", "a11", "a5", "a15"],
  5: ["a1", "a4", "a7", "a18", "a22"],
  6: ["a1", "a3", "a5", "a18", "a20", "a22"],
  7: ["a1", "a3", "a5", "a7", "a18", "a20", "a22"],
  8: ["a1", "a3", "a5", "a7", "a10", "a12", "a14", "a19"],
  9: ["a1", "a3", "a5", "a7", "a10", "a12", "a14", "a19", "a21"],
  10: ["a2", "a4", "a6", "a9", "a11", "a13", "a15", "a18", "a20", "a22"],
  11: ["a1", "a3", "a5", "a7", "a10", "a12", "a14", "a17", "a19", "a21", "a23"],
  12: [
    "a1",
    "a3",
    "a5",
    "a7",
    "a10",
    "a12",
    "a14",
    "a17",
    "a19",
    "a21",
    "a23",
    "a28",
  ],
};

function HappyHour(props) {
  const [displayingSplash, setDisplayingSplash] = useState(false);
  const [turnPlayerName, setTurnPlayerName] = useState("");
  const [turnPlayer, setTurnPlayer] = useState(0);
  const [closeSplashAnimation, setCloseSplashAnimation] = useState(false);
  const [timeToShowAlert, setTimeToShowAlert] = useState(true);
  const [displayingAlert, setDisplayingAlert] = useState(false);
  const [alertQueued, setAlertQueued] = useState("");
  const [closeResultsAnimation, setCloseResultsAnimation] = useState(false);
  const [displayingResults, setDisplayingResults] = useState(false);
  const [timeToRoll, setTimeToRoll] = useState(false);
  const socketDataRef = useRef(props.socketData);
  const [totalPlayerNum, setTotalPlayerNum] = useState(1);

  useEffect(() => {
    if (props.lobby) {
      setTotalPlayerNum(getActivePlayers());
      if (getTurnPlayer()) {
        setTurnPlayer(props.lobby.turnPlayer);
        setTurnPlayerName(getTurnPlayer().name);
      }
    }
  }, [props.lobby]);

  useEffect(() => {
    if (
      (props.lobby.turnPlayer === props.yourId && !props.showLeaders) ||
      true
    ) {
      setTimeToRoll(true);
    } else {
      setTimeToRoll(false);
    }
  }, []);

  useEffect(() => {
    if (props.tileData) {
      let uid = props.tileData["uid"];
      setTimeToShowAlert(false);
      if (props.tileData.alertPayload && uid === props.yourId) {
        props.sendEvent("send alert", props.tileData.alertPayload);
      }
      startMiniGame();
    }
  }, [props.tileData]);

  useEffect(() => {
    if (props.alert) {
      setAlertQueued(props.alert);
    }
  }, [props.alert]);

  useEffect(() => {
    socketDataRef.current = props.socketData;
    if (props.socketData) {
      handleSocketData(props.socketData);
    }
  }, [props.socketData]);

  function getTurnPlayerColor() {
    const tp = props.lobby.players.find(
      (u) => u.uid === props.lobby.turnPlayer
    );
    if (tp) {
      return playerColors[tp.iconId];
    }
    return "white";
  }

  function handleSocketData(payload) {
    switch (payload.type) {
      case "vote results":
        if (props.tileData.hasOwnProperty("noVoteResult")) {
          //Do nothing
        } else {
          setDisplayingSplash(false);
          setDisplayingResults(true);
        }
        break;
      case "input results":
        if (props.tileData.hasOwnProperty("noLeaderboard")) {
          console.log("");
        } else {
          setDisplayingSplash(false);
          setDisplayingResults(true);
        }
        break;
      case "end turn":
        const resultPage = document.querySelector(".resultPage");
        const splashPage = document.querySelector(".splashContainer");
        const container = resultPage || splashPage;

        addMainAnimations();
        if (container) {
          if (splashPage) {
            const container = document.querySelector(".waveClass");
            container.style.animation =
              "emptyDownScreen 1s ease-out 1 forwards";

            const splash = document.querySelector(".splashContainer");
            if (splash) {
              setTimeout(() => {
                splash.style.opacity = 0;
              }, 100);
            }
          }
          setTimeout(
            () => {
              setDisplayingSplash(false);
              setDisplayingResults(false);
              setTimeToShowAlert(true);
              if (alertQueued) {
                setDisplayingAlert(true);
              }
              if (props.lobby.turnPlayer === props.yourId || true) {
                setTimeToRoll(true);
              } else {
                setTimeToRoll(false);
              }
            },
            splashPage ? 1500 : 0
          );
        } else {
          setTimeout(() => {
            setDisplayingSplash(false);
            setDisplayingResults(false);
            setTimeToShowAlert(true);
            if (alertQueued) {
              setDisplayingAlert(true);
            }
            if (props.lobby.turnPlayer === props.yourId || true) {
              setTimeToRoll(true);
            } else {
              setTimeToRoll(false);
            }
          }, 1500);
        }

        break;
    }
  }

  function getTurnPlayer() {
    return props.lobby.players.find((u) => u.uid === props.lobby.turnPlayer);
  }

  function getActivePlayers() {
    let activePlayers = 0;
    props.lobby.players.forEach((player) => {
      if (player.connected) {
        activePlayers += 1;
      }
    });
    return activePlayers;
  }

  const handleDoneSplash = () => {
    setDisplayingSplash(false);
    setCloseSplashAnimation(false);
    window.scrollTo(0, 0);
  };

  const handleDoneResults = () => {
    setDisplayingResults(false);
    setCloseResultsAnimation(false);
    window.scrollTo(0, 0);
  };

  const handleDoneAlert = () => {
    setAlertQueued(null);
    setDisplayingAlert(false);
    setTimeToShowAlert(false);
  };

  function getPlacement(index) {
    return playerPositions[totalPlayerNum][index];
  }

  function getIconSize() {
    if ([1, 2].includes(totalPlayerNum)) {
      if (window.innerHeight > window.innerWidth) {
        return 45;
      }
      return 33;
    }
    if ([3, 4, 5].includes(totalPlayerNum)) {
      if (window.innerHeight > window.innerWidth) {
        return 45;
      }
      return 25;
    }
    if ([6, 7].includes(totalPlayerNum)) {
      if (window.innerHeight > window.innerWidth) {
        return 30;
      }
      return 20;
    }
    if ([8, 9].includes(totalPlayerNum)) {
      if (window.innerHeight > window.innerWidth) {
        return 30;
      }
      return 15;
    }
    if ([10, 11, 12].includes(totalPlayerNum)) {
      if (window.innerHeight > window.innerWidth) {
        return 23;
      }
      return 13;
    }
  }

  function getIdlePlayerStyles(index) {
    const iconSize = getIconSize();
    return {};
  }

  // function renderTiles(){
  //     let tiles = []
  //     let index = 0;
  //     for(let i = 0; i<20; i++){

  //         tiles.push(<div style={{backgroundColor: index%2==0?'black':playerColors[getTurnPlayer().iconId], gridArea:"d"+i, zIndex: -5}}>
  //         </div>)
  //         index+=1;
  //     }
  //     return tiles
  // }

  function genMiniGame() {
    setTimeToRoll(false);
    stageInfiniteTurnData(props);
  }

  function startMiniGame() {
    setTimeToRoll(false);
    setDisplayingAlert(false);
    setDisplayingSplash(true);
    clearMainAnimations();
  }

  function clearMainAnimations() {
    setTimeout(() => {
      const icon = document.querySelector(".turnPlayerIcon");
      icon.style.opacity = 0;
      const banner = document.querySelector(".happyHourSign");
      banner.style.opacity = 0;
    }, 500);

    const slider = document.querySelector(".turnSliderContainer");

    slider.style.opacity = 0;
  }

  function addMainAnimations() {
    const slider = document.querySelector(".turnSliderContainer");
    slider.style.opacity = 1;
    slider.style.animation = "none";
    const sliderIcon = document.querySelector(".firstSlider");
    sliderIcon.classList.add("dull");
    setTimeout(() => {
      slider.style.animation = "slideLeft 1s ease-in-out .5s forwards";
    }, 250);
    setTimeout(() => {
      const icon = document.querySelector(".turnPlayerIcon");
      icon.style.opacity = 1;
      //sliderIcon.classList.add("first")
      sliderIcon.classList.remove("dull");
    }, 1500);
    setTimeout(() => {
      const banner = document.querySelector(".happyHourSign");
      banner.style.opacity = 1;
    }, 2000);
  }

  function renderDrinkIcons() {
    let icons = [];
    props.lobby.players.forEach((player) => {
      if (player.connected) {
        icons.push(
          <img
            className="happyHourIdlePlayer"
            style={{
              opacity: player.seatNum === getTurnPlayer().seatNum ? 0 : 0.2,
            }}
            src={`./icons/${iconPaths[player.iconId]}`}
          />
        );
      }
    });
    return icons;
  }

  function renderTurnPlayerDrink() {
    if (getTurnPlayer()) {
      return (
        <img
          className="turnPlayerIcon"
          style={{
            "--drWidth":
              window.innerHeight > window.innerWidth ? "100vw" : "20vw",
          }}
          src={`./icons/${iconPaths[getTurnPlayer().iconId]}`}
        />
      );
    }
  }

  function renderAlert(alertQueued) {
    const alertMsg = alertQueued.split("%%%")[0];
    return (
      <button className="alertBtn" onClick={handleDoneAlert}>
        {" "}
        {alertMsg}{" "}
      </button>
    );
  }

  function renderAlerts() {
    if (alertQueued) {
      return renderAlert(alertQueued);
    }
    if (timeToRoll) {
      return (
        <DrContent>
          <button className="startMiniGame" onClick={genMiniGame}>
            {" "}
            Start Turn
          </button>
        </DrContent>
      );
    }
  }

  function renderForegound() {
    if (displayingSplash) {
      return (
        <Splash
          tileData={props.tileData}
          handleClose={handleDoneSplash}
          sendEvent={props.sendEvent}
          closeTriggered={closeSplashAnimation}
          turnPlayer={turnPlayer}
          turnPlayerName={turnPlayerName}
          yourId={props.yourId}
          challengeData={props.challengeData}
          socketData={props.socketData}
          lifeRaftPrompts={props.library["lifeRaft"]}
          splashes={props.splashes}
          players={props.lobby.players}
          sLvl={props.lobby.sLvl}
          dLvl={props.lobby.dLvl}
          board={false}
        />
      );
    } else if (displayingResults) {
      return (
        <Results
          socketData={props.socketData}
          handleClose={handleDoneResults}
          sendEvent={props.sendEvent}
          turnPlayer={turnPlayer}
          yourId={props.yourId}
          closeTriggered={closeResultsAnimation}
          prize={props.tileData.prize}
          lobby={props.lobby}
          turnPlayerName={turnPlayerName}
          tileData={props.tileData}
        />
      );
    }
  }

  function renderPage() {
    const turnColor = getTurnPlayer()
      ? playerColors[getTurnPlayer().iconId]
      : "white";
    const turnFontSize = getTurnPlayer()
      ? getTurnPlayer().name.length > 9
        ? "4.5vh"
        : "5vh"
      : "5vh";
    return (
      <DrContent>
        <div
          className="happyHourSign"
          style={{
            color: turnColor,
            fontSize: turnFontSize,
            textShadow: `0 0 1px ${turnColor}`,
          }}
        >
          {props.bannerMsg}
        </div>
        <div className="happyHourContent">{renderTurnPlayerDrink()}</div>
        <TurnSlider lobby={props.lobby} key={props.turnNum} />
      </DrContent>
    );
  }
  /*
        <BarDesign style={{borderColor: playerColors[getTurnPlayer().iconId]}}>
            {renderTiles()}
        </BarDesign>
    */

  return (
    <DrPage key={"happyHourPage" + props.turnNum}>
      <ul
        class={`bubbles-hh ${
          displayingResults || displayingSplash ? "hidden" : ""
        }`}
        style={{ "--bubble-color": getTurnPlayerColor() }}
      >
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <GameHeader lobby={props.lobby} />
      {renderPage()}
      {renderForegound()}
      {renderAlerts()}
    </DrPage>
  );
}

export default HappyHour;
