import React, { useState, useEffect, useRef } from "react";
import "../Splash.css";
import styled from "styled-components";
import { DotPulse, Ping } from "@uiball/loaders";
import ChallengeGame from "./ChallengeGame.js";
import ChallengeResult from "./ChallengeResult.js";
import LifeRaft from "./LifeRaft.js";
import Chance from "./Chance.js";
import DelayedPrompt from "./Challenge/DelayedPrompt.js";
import Wave from "react-wavify";
import DrEvent from "../display/DrEvent.js";
import NavButton from "./NavButton.js";

const SplashArt = styled.img`
  width: 100%;
  height: auto;
  backface-visibility: hidden;
  position: absolute;
`;

const Page = styled.div`
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  z-index: 5;
`;

function Splash(props) {
  let dev = false;
  const [flipped, setFlipped] = useState(false);
  const [pageDeck, setPageDeck] = useState([]);
  const [pageComponent, setPageComponent] = useState(null);
  const [pageNum, setPageNum] = useState(0);
  const [picking, setPicking] = useState(false);
  const pageNumRef = useRef(pageNum);
  const [startTurnEpoch, setStartTurnEpoch] = useState(0);
  const [movingWave, setMovingWave] = useState(false);

  useEffect(() => {
    pageNumRef.current = pageNum; // Update the ref value whenever pageNum changes
  }, [pageNum]);

  useEffect(() => {
    if (props.closeTriggered) {
      closeSplash();
    }
  }, [props.closeTriggered]);

  useEffect(() => {
    loadDeck();
    const container = document.querySelector(".splashContainer");
    if (props.board) {
      const { top, left, width, height } = props.tileDims;
      container.style.position = "absolute";
      container.style.top = top + "px";
      container.style.left = left + "px";
      container.style.width = width + "px";
      container.style.height = height + "px";

      container.classList.add("zoomTile");

      //Match the category theme
      let themeColor = getGradientBottom(props.tileData.categoryId);
      const board = document.querySelector(".gameBoardBody");
      const banner = document.querySelector(".bannerText");
      if (board && banner) {
        board.style.borderColor = "#151515";
        banner.style.textShadow = `0 0 15px ${themeColor}`;
        banner.style.color = themeColor;
      }
      // Trigger Flip, should be 4000
    } else {
      container.classList.add("miniGameCard");
      container.style.position = "absolute";
      container.style.top = "110vh";
      container.classList.add("zoomTile");
    }
    const timer = setTimeout(
      () => {
        container.style.transform = "";
        //setPageNum(pageNum + 1);
        const t = new Date();
        setMovingWave(true);
        triggerAnimation("pickerContainer");
        setStartTurnEpoch(t);
      },
      dev ? 100 : 850
    );

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleFlip = () => {
    // setFlipped(!flipped);
  };

  useEffect(() => {
    if (movingWave && pageDeck && pageNum && pageDeck[pageNum]) {
      triggerAnimation(pageDeck[pageNum].selectorName);
    }
  }, [movingWave, pageDeck, pageNum]);

  useEffect(() => {
    if (pageDeck.length && pageNum === pageDeck.length) {
      closeSplash();
    } else {
      if (pageDeck[pageNum]) {
        setPageComponent(pageDeck[pageNum].component);
        triggerAnimation(pageDeck[pageNum].selectorName);
      }
      if (pageNum > 0 && pageNum !== pageDeck.length) {
        (props.socketData &&
          (props.socketData["type"] === "chal update" ||
            props.socketData["type"] === "vote results")) ||
          handleFlip();
      }
    }
  }, [pageDeck, pageNum]);

  useEffect(() => {
    if (picking) {
      setTimeout(() => {
        let options = Array.from(document.querySelectorAll(".optionRow"));
        let normalDelay = 150;
        let lastLap = 5;
        let foundSelected = false;

        const executeLoop = async (optionIndex) => {
          return new Promise((resolve) => {
            if (!foundSelected) {
              options[optionIndex].classList.add("highlighted");
              setTimeout(() => {
                options[optionIndex].classList.remove("highlighted");
                resolve(); // Resolve the promise to indicate loop iteration completion
              }, normalDelay);
            }
          });
        };

        const runLoops = async () => {
          // Spin normally
          for (let lapNum = 0; lapNum < lastLap; lapNum++) {
            for (
              let optionIndex = 0;
              optionIndex < options.length;
              optionIndex++
            ) {
              normalDelay += 20;
              await executeLoop(optionIndex);
            }
          }

          // Pick lap
          for (
            let optionIndex = 0;
            optionIndex < options.length;
            optionIndex++
          ) {
            if (
              options[optionIndex].textContent ===
              props.tileData["selectionTitle"]
            ) {
              options[optionIndex].classList.add("selected");
              foundSelected = true;
              setTimeout(() => {
                setPageNum(pageNum + 1);
                setPicking(false);
                options[optionIndex].classList.remove("selected");
                return;
              }, 1750);
            } else {
              normalDelay += 100;
              await executeLoop(optionIndex);
            }
          }
        };

        runLoops();
      }, 1000);
    }
  }, [picking]);

  useEffect(() => {
    if (
      props.socketData &&
      (props.socketData.type === "chance update" ||
        props.socketData["type"] === "chal update" ||
        props.socketData["type"] === "input results" ||
        props.socketData["style"] === "quip vote")
    ) {
      loadDeck();
      if (props.socketData["turnPage"]) {
        setPageNum(pageNumRef.current + 1);
      }
      if (props.tileData.hasOwnProperty("uniquePrompts")) {
        //setDelayedReveal(true);
      }
    }
  }, [props.socketData]);

  function sendNextPage(e) {
    e.preventDefault();
    let payload = props.tileData["challengeJson"];
    if (!payload) {
      payload = props.tileData["varData"];
    }
    payload["type"] = "chal update";
    payload["turnPage"] = true;

    props.sendEvent("chal action", payload);
  }

  function handleNextBtn(e) {
    if (e) {
      e.preventDefault();
    }
    setPageNum(pageNumRef.current + 1);
  }

  function sendPlayerToStart(playerId) {
    let payload = {};
    payload["originalUser"] = playerId;
    payload["swapPlace"] = "start";
    props.sendEvent("position swap", payload);
  }

  function renderExpiration(groupRule, expiration) {
    return groupRule ? (
      <p className="promptSubtitle">
        {props.prevRule
          ? `This replaces ${props.prevRule}`
          : `Lasts until a new rule is made`}
      </p>
    ) : (
      <p className="promptSubtitle">{expiration}</p>
    );
  }

  function renderSecretPrompt() {
    if (props.yourId === props.turnPlayer) {
      return (
        <p className="promptSubtitle">
          Only you can see this secret question:
          <br />
          <span style={{ color: getGradientTop(props.tileData.categoryId) }}>
            {props.tileData.varData}
          </span>
        </p>
      );
    } else {
      return <></>;
    }
  }

  function getGradientTop(categoryId) {
    switch (categoryId) {
      case "1":
        return "#FFCCFF";
      case "2":
        return "#ffffff";
      case "3":
        return "#33FFFF";
      case "4":
        return "#FFFFFF";
      case "6":
        return "#33FFFF";
      case "7":
        return "#FF9933";
      case "8":
        return "#FFFF66";
      case "9":
        return "#FFFFFF";
      case "10":
        return "#FFFFFF";
      case "11":
        return "magenta";
      default:
        return "#FFFFFF";
    }
  }

  function getGradientBottom(categoryId) {
    switch (categoryId) {
      case "1":
        return "#D58DFF";
      case "2":
        return "#99CCFF";
      case "3":
        return "#99CCFF";
      case "4":
        return "#FF3333";
      case "6":
        return "#FFFFFF";
      case "7":
        return "#FFFF66";
      case "8":
        return "#FF3333";
      case "9":
        return "#FF3333";
      case "10":
        return "#FF6699";
      case "11":
        return "#FF3333";
      case "13":
        return "#FF33CC";
      default:
        return "#99FFCC";
    }
  }

  const playerColors = [
    "#00FFFF",
    "#FF0000",
    "#FFFF00",
    "#99FF00",
    "#339933",
    "#FF9450",
    "#990050",
    "#FF9900",
    "#9966FF",
    "#FFFFFF",
    "#FF99C3",
    "#0066CC",
  ];

  function getVotingRenderStyle(twoChoices) {
    if (twoChoices) {
      return {};
    }
    if (props.tileData["voteOptions"].length > 6) {
      return {
        gridTemplateColumns: "repeat(auto-fit, minmax(auto, 50%))",
        gridAutoRows: "11%",
      };
    }
    return {
      gridTemplateColumns: "repeat(auto-fit, minmax(auto, 70%))",
      gridAutoRows: "11%",
    };
  }

  function getTurnPlayerColor() {
    const tp = props.players.find((u) => u.uid === props.turnPlayer);
    return playerColors[tp.iconId];
  }

  function getCategoryHighlight(categoryId) {
    return "white";
    switch (categoryId) {
      case "1":
        return "magenta";
      case "2":
        return "magenta";
      case "3":
        return "white";
      case "4":
        return "white";
      case "5":
        return "magenta";
      case "6":
        return "magenta";
      case "7":
        return "white";
      case "8":
        return "white";
      case "9":
        return "white";
      case "10":
        return "magenta";
      case "11":
        return "magenta";
      default:
        return "magenta";
    }
  }

  function smallTextNeeded(rules) {
    if (typeof rules !== "string") {
      throw new Error("Input must be a string");
    }
    const commaCount = (rules.match(/•/g) || []).length;
    return commaCount > 2;
  }

  function highlight(text) {
    const tp = props.players.find((u) => u.uid === props.turnPlayer);
    const groupNameStyle = {
      color: getCategoryHighlight(props.tileData["categoryId"]),
    };
    const playerNameStyle = { color: playerColors[tp.iconId] };
    text = text.replace(
      /<any>/g,
      `<span style="${Object.entries(groupNameStyle)
        .map(([key, value]) => `${key}:${value}`)
        .join(";")}" class="playerName">Anyone</span>`
    );
    text = text.replace(
      /<all>/g,
      `<span style="${Object.entries(groupNameStyle)
        .map(([key, value]) => `${key}:${value}`)
        .join(";")}" class="playerName">Everyone</span>`
    );
    return text.replace(
      /<name>/g,
      `<span style="${Object.entries(playerNameStyle)
        .map(([key, value]) => `${key}:${value}`)
        .join(";")}" class="playerName">${props.turnPlayerName}</span>`
    );
  }

  /*
    p : picking Events
    r : rules
    c : challenge
    v : voting 
    o : outcome
    w : waiting
*/

  function loadDeck() {
    let deck = [];
    // SPLASH ART
    let selectorName;
    let splashComponent;
    let splashUrl = "";
    let pickerTitle = "";
    let orderId = 1;
    let skipRules = false;

    let otherPlayerVoting =
      props.tileData["voteOptions"] &&
      props.yourId !== props.turnPlayer &&
      !props.tileData["groupElect"];
    switch (props.tileData["categoryId"]) {
      case "1":
        pickerTitle = "Take Drinks";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Take Drinks.png";
        break;
      case "2":
        pickerTitle = "Give Drinks";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Give Drinks.png";
        break;
      case "3":
        pickerTitle = "Drink If...";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Drink If.png";
        break;
      case "4":
        pickerTitle = "Challenge";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Solo.png";
        break;
      case "5":
        pickerTitle = "All Play";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_All Play.png";
        break;
      case "6":
        pickerTitle = "Duel";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Duel.png";
        break;
      case "7":
        pickerTitle = "Rule";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Rule.png";
        break;
      case "8":
        pickerTitle = "Hot Seat";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Hot Seat.png";
        break;
      case "11":
        pickerTitle = "Unlucky";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Bad Luck.png";
        break;
      case "9":
        pickerTitle = "Dare";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Dare.png";
        break;
      case "10":
        pickerTitle = "Chance";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Chance.png";
        break;
      case "12":
        pickerTitle = "Game Over";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_End.png";
        break;
      case "13":
        pickerTitle = "Group Vote";
        selectorName = "splashArt";
        splashUrl = "./splashes/thumb_Chance.png";
        break;
      default:
        selectorName = "splashArt";
        splashUrl = "./splashes/duel-splash-fast.png";
    }
    let splashId = parseInt(props.tileData["categoryId"]) - 1;
    //TODO add group vote at the end
    if (splashId === 12) {
      splashId = 4;
    }
    splashComponent = props.splashes ? props.splashes[splashId] : null;
    //splashComponent = <SplashArt className='splashArt' src={splashUrl} alt='splashArt' />
    /*
        deck.push({
            'selectorName': selectorName,
            'component': splashComponent
        })
        */
    if (props.tileData["eventOptions"]) {
      let pickScreen = (
        <DrEvent
          className="pickerContainer"
          style={{ transform: odd(orderId) ? "rotateY(180deg)" : "" }}
        >
          <div className="titleContainer">{pickerTitle}</div>
          <div className="pickContainer">{renderEventOptions()}</div>
        </DrEvent>
      );
      if (["11"].includes(props.tileData["categoryId"])) {
        deck.push({
          selectorName: "pickerContainer",
          component: pickScreen,
        });
        orderId++;
      }
    }

    let challengeJson = props.tileData["challengeJson"];
    if (challengeJson && challengeJson["skipRules"]) {
      skipRules = true;
    }

    if (!skipRules && props.tileData["selectionRules"]) {
      let groupAction = props.tileData["groupElect"];
      let syncNeeded =
        (props.tileData["challengeJson"] &&
          props.tileData["challengeJson"]["syncNeeded"]) ||
        props.tileData["syncNeeded"];

      let navText = "START";
      if (!props.tileData.hasOwnProperty("uniquePrompts") && !challengeJson) {
        navText = "END TURN";
      }

      const numActivePlayers = props.players.filter(
        (player) => player.connected && !player.waiting
      ).length;
      const listNeeded = props.tileData.hasOwnProperty("list");
      const playerNumDependent = props.tileData.hasOwnProperty(
        "playerDependent"
      )
        ? numActivePlayers
        : null;
      const lifeRaftNeeded = props.tileData.hasOwnProperty("lifeRaft");
      const emojiHeaderNeeded = props.tileData.hasOwnProperty("emojiHeader");

      let shuffledLifeRaft;
      const yourTheTurnPlayer = props.yourId === props.turnPlayer;
      if (
        props.tileData.selectionTitle === "Back To Start" &&
        yourTheTurnPlayer
      ) {
        sendPlayerToStart(props.yourId);
      }
      const inverseLifeRaft = props.tileData.hasOwnProperty("inverseLifeRaft");
      //Situational dressing
      if (lifeRaftNeeded) {
        let prompts =
          props.lifeRaftPrompts[props.tileData.selectionTitle.toLowerCase()];
        for (let i = prompts.length - 1; i > 0; i--) {
          const randomIndex = Math.floor(Math.random() * (i + 1));
          [prompts[i], prompts[randomIndex]] = [
            prompts[randomIndex],
            prompts[i],
          ];
        }
        shuffledLifeRaft = prompts;
      }
      //List prefix
      let prefix = "";
      if (props.tileData.hasOwnProperty("prefix")) {
        prefix = props.tileData["prefix"];
      }
      let luckyCoin = props.tileData["selectionTitle"] === "Lucky Coin";
      //{emojiHeaderNeeded?<><span className='ruleEmoji'>{props.tileData['emojiHeader']+"\n"}</span></>:null}
      //secret prompt
      let secretPromptNeeded = props.tileData.hasOwnProperty("secretPrompt");
      let rules = highlight(props.tileData["selectionRules"]);
      let rulesScreen = (
        <DrEvent className="rulesContainer">
          <div className="rulesTitleContainer">
            {props.tileData["selectionTitle"]}
          </div>
          <div
            className="rulesText"
            style={{
              paddingTop: emojiHeaderNeeded ? "3%" : "8%",
              fontSize: smallTextNeeded(props.tileData["selectionRules"])
                ? "2.25vh"
                : "2.9vh",
            }}
          >
            {emojiHeaderNeeded ? (
              <>
                <span>
                  <img
                    className="eventIcon"
                    src={`./eventIcons/${props.tileData[
                      "selectionTitle"
                    ].toLowerCase()}.png`}
                  />
                </span>
              </>
            ) : null}
            <p
              className="rules"
              dangerouslySetInnerHTML={{ __html: rules }}
            ></p>
            {listNeeded
              ? renderList(props.tileData.varData, prefix, playerNumDependent)
              : null}
            {secretPromptNeeded ? renderSecretPrompt() : null}
          </div>
          <>
            {(lifeRaftNeeded && !inverseLifeRaft) ||
            (inverseLifeRaft && !yourTheTurnPlayer) ? ( //full controls: youtTheTurnPlayer && in first one
              <LifeRaft lifeRaftPrompts={shuffledLifeRaft} />
            ) : null}
          </>
          <>
            {!luckyCoin || groupAction ? (
              <NavButton
                delay={35}
                text={navText}
                action={syncNeeded ? sendNextPage : handleNextBtn}
              />
            ) : null}
          </>
          <>
            {props.yourId === props.turnPlayer && luckyCoin ? (
              <div className="luckyCoinNavContainer">
                <button className="luckyCoinNav" onClick={sendNextPage}>
                  {"FLIP COIN"}
                </button>
                <button className="luckyCoinNav" onClick={closeSplash}>
                  {"SKIP"}
                </button>
              </div>
            ) : null}
          </>
        </DrEvent>
      );
      deck.push({
        selectorName: "rulesContainer",
        component: rulesScreen,
      });
      orderId++;
    }

    if (props.tileData.hasOwnProperty("uniquePrompts")) {
      const uniquePrompt = (
        <DelayedPrompt
          tileData={props.tileData}
          handleNextBtn={handleNextBtn}
          yourId={props.yourId}
          turnPlayer={props.turnPlayer}
        />
      );
      deck.push({
        selectorName: "uniquePromptContainer",
        component: uniquePrompt,
      });
    }

    if (
      props.tileData["categoryId"] === "10" &&
      props.tileData["selectionTitle"] === "Chance"
    ) {
      let chancePage = (
        <Chance
          playerList={props.tileData.playerList}
          chanceList={props.tileData.chanceList}
          sendEvent={props.sendEvent}
          handleNextBtn={handleNextBtn}
          turnPlayer={props.turnPlayer}
          turnPlayerName={props.turnPlayerName}
          yourId={props.yourId}
          socketData={props.socketData}
        />
      );

      deck.push({
        selectorName: "chanceContainer",
        component: chancePage,
      });
    }

    if (challengeJson) {
      let challengeGamePage = (
        <ChallengeGame
          challengeJson={challengeJson}
          tileData={props.tileData}
          socketData={props.socketData}
          yourId={props.yourId}
          title={props.tileData["selectionTitle"]}
          turnPlayer={props.turnPlayer}
          turnPlayerName={props.turnPlayerName}
          flipped={odd(orderId)}
          handleClose={closeSplash}
          sendEvent={props.sendEvent}
          handleNextBtn={handleNextBtn}
          players={props.players}
          sLvl={props.sLvl}
          dLvl={props.dLvl}
        />
      );
      deck.push({
        selectorName: "challengeContainer",
        component: challengeGamePage,
      });
      orderId++;
    }

    if (
      challengeJson &&
      challengeJson["penalty"] &&
      !challengeJson["skipResults"]
    ) {
      let { prompt, answer, choices } = challengeJson["varData"];
      let challengeResultPage = (
        <ChallengeResult
          challengeJson={challengeJson}
          socketData={props.socketData}
          title={props.tileData["selectionTitle"]}
          yourId={props.yourId}
          turnPlayer={props.turnPlayer}
          flipped={odd(orderId)}
          handleClose={closeSplash}
          sendEvent={props.sendEvent}
          choices={choices}
          answer={answer}
          prompt={prompt}
          turnPlayerName={props.turnPlayerName}
          playerColor={getTurnPlayerColor()}
        />
      );
      deck.push({
        selectorName: "challengeResultsContainer",
        component: challengeResultPage,
      });
      orderId++;
    }

    if (
      (props.tileData["voteOptions"] && props.yourId === props.turnPlayer) ||
      props.tileData["groupElect"]
    ) {
      let twoChoices =
        props.tileData["voteOptions"].length === 2 && props.tileData["twoVote"];

      let header = props.tileData["votePrompt"] ? (
        <>
          <div className="rulesTitleContainer">
            {props.tileData["selectionTitle"]}
          </div>
          {twoChoices ? (
            <>
              <span>
                <img
                  className="eventIcon"
                  src={`./eventIcons/${props.tileData[
                    "selectionTitle"
                  ].toLowerCase()}.png`}
                />
              </span>
            </>
          ) : null}
          <div
            className="votePrompt"
            style={{ marginTop: twoChoices ? "10%" : null }}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: highlight(props.tileData["votePrompt"]),
              }}
            ></p>
          </div>
        </>
      ) : (
        <div className="rulesTitleContainer">
          {props.tileData["selectionTitle"]}
        </div>
      );

      let voteScreen = (
        <DrEvent className="voteContainer">
          {header}
          <div
            className={twoChoices ? "twoChoiceContent" : "voteContent"}
            style={getVotingRenderStyle(twoChoices)}
          >
            {renderVotingOptions(twoChoices)}
          </div>
        </DrEvent>
      );

      deck.push({
        selectorName: "voteContainer",
        component: voteScreen,
      });
      orderId++;
    }

    let waitingMsg = "Waiting for other players";
    if (otherPlayerVoting) {
      waitingMsg = `${
        props.turnPlayerName
      } is giving someone a ${props.tileData.recieveMsg.toLowerCase()}`;
    }

    if (
      props.tileData["groupElect"] ||
      otherPlayerVoting ||
      props.tileData["groupResponse"]
    ) {
      let waitScreen = (
        <DrEvent
          className="waitContainer"
          style={{ transform: odd(orderId) ? "rotateY(180deg)" : "" }}
        >
          <div className="waitingTitle">{waitingMsg}</div>
          <div className="waitingGifContainer">
            <DotPulse size={90} speed={1.15} color="black" />
          </div>
        </DrEvent>
      );
      deck.push({
        selectorName: "waitContainer",
        component: waitScreen,
      });
      orderId++;
    }

    //Do a reult screen outside, then we can reuse as drinkboard and not listen for sockets in here
    // Handle close
    setPageDeck(deck);
  }

  function triggerAnimation(pageSelector) {
    switch (pageSelector) {
      case "pickerContainer":
        setPicking(true);
        break;
    }
  }

  async function closeSplash() {
    const endTurnEpoch = new Date();
    setMovingWave(false);
    if (!props.closeTriggered) {
      //&& props.turnPlayer === props.yourId TODO
      if (props.tileData.categoryId === "12") {
        props.sendEvent("end reached", {});
      } else {
        props.sendEvent("end turn");
      }
    }
  }

  function odd(i) {
    return false;
    return i % 2 !== 0;
  }

  function sendVote(id, voteType) {
    if (props.tileData["groupElect"]) {
      const payload = {
        voteId: id,
        voteType: voteType,
      };
      props.sendEvent("send vote", payload);
    } else {
      const msg = "You recieved a " + props.tileData.recieveMsg;
      const payload = {
        userList: [id],
        msg: msg,
      };

      props.sendEvent("send alert", payload);
    }
  }

  function votePicked(e) {
    e.preventDefault();

    sendVote(
      e.currentTarget.getAttribute("vote-id"),
      e.currentTarget.getAttribute("vote-type")
    );

    e.currentTarget.style.backgroundColor = "#99ffcc";
    e.currentTarget.style.color = "var(--offBlack)";

    setPageNum(pageNumRef.current + 1);
  }

  async function randVote(e) {
    e.preventDefault();
    const randomButton = e.currentTarget;
    randomButton.style.visibility = "hidden";

    let prevNum = -1;
    let curNum = 1;
    const voteButtons = document.querySelectorAll(".voteOption");
    voteButtons.forEach((button) => {
      button.disabled = true;
    });
    const iterations = Math.floor(Math.random() * 11) + 10;
    if (voteButtons.length > 2) {
      for (let i = 0; i < iterations; i++) {
        // Reset the background color of the previous button if prevNum is not -1
        if (prevNum !== -1) {
          voteButtons[prevNum].style.backgroundColor = "var(--offBlack)";
        }
        // Choose a new button index while making sure it is not the same as the previous one
        while (curNum === prevNum) {
          curNum =
            1 +
            Math.floor(
              Math.random() * (props.tileData["voteOptions"].length - 1)
            );
        }

        // Set the background color of the current button to white
        voteButtons[curNum].style.backgroundColor = "white";

        // Wait for 25ms
        await new Promise((resolve) => setTimeout(resolve, 100));

        // Update prevNum for the next iteration
        prevNum = curNum;
      }
    }

    // Set the background color of the last picked button to green
    voteButtons[curNum].style.backgroundColor = "#99ffcc";
    voteButtons[curNum].style.color = "var(--offBlack)";
    sendVote(
      voteButtons[curNum].getAttribute("vote-id"),
      voteButtons[curNum].getAttribute("vote-type")
    );

    // Sleep for 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setPageNum(pageNumRef.current + 1);
  }

  function getOptionStyle(twoChoices, index) {
    if (twoChoices && props.tileData["twoVote"]) {
      return {
        borderColor: "black",
      };
    }
  }

  function renderVotingOptions(twoChoices) {
    let options = [];
    props.tileData["voteOptions"].forEach((option, index) => {
      if (option.id !== "Random") {
        let shorterName = option["name"];
        if (
          option["name"].length > 7 &&
          props.tileData["voteOptions"].length > 6
        ) {
          shorterName = option["name"].slice(0, 7) + "..";
        }
        options.push(
          <button
            className="voteOption"
            vote-id={option.id}
            key={option.id}
            vote-type={props.tileData["voteType"]}
            onClick={votePicked}
            style={getOptionStyle(twoChoices, index)}
          >
            {twoChoices ? option["name"] : shorterName}
          </button>
        );
      } else {
        options.push(
          <button
            className="voteOption randomVoteButton"
            key={options.id}
            vote-type={props.tileData["voteType"]}
            onClick={randVote}
          >
            RANDOM
          </button>
        );
      }
    });
    return options;
  }

  function renderList(list, prefix, playerNumDependent) {
    const listElements = list.hasOwnProperty("options")
      ? list["options"]
      : list;
    return (
      <div className="promptList">
        {renderPromptListItems(listElements, prefix, playerNumDependent)}
      </div>
    );
  }

  function renderPromptListItems(list, prefix, playerNumDependent) {
    let items = [];
    let textDelay = 0.5;
    let maxListLength = playerNumDependent ? playerNumDependent : list.length;
    list.forEach((text) => {
      items.length < maxListLength
        ? items.push(
            <div
              className="promptListItem"
              style={{
                opacity: 0,
                animation: `reveal 1s ${textDelay}s forwards`,
              }}
            >
              {prefix}
              {text}
            </div>
          )
        : console.log();
      textDelay += 0.25;
    });
    return items;
  }

  function renderEventOptions() {
    let options = [];
    let optionNum = 0;
    props.tileData["eventOptions"].forEach((option) => {
      options.push(
        <div className="optionRow" key={optionNum}>
          <p>{option["title"]}</p>
        </div>
      );
      optionNum++;
    });
    return options;
  }

  /* Bubbles timeout

            <ul className="bubbles-card"
            style={{ '--bubble-color': getGradientTop(props.tileData['categoryId']) }}>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>

*/

  return (
    <div className={`splashContainer ${flipped ? "flipped" : ""}`}>
      {props.tileData["categoryId"] &&
      props.tileData["categoryId"] === "12" ? null : (
        <>
          <Wave
            options={{ speed: 0.35, height: 10, amplitude: 30 }}
            className={"waveClass"}
            fill="url(#gradient)"
            paused={!movingWave}
          >
            <defs>
              <linearGradient id="gradient" gradientTransform="rotate(90)">
                <stop
                  offset="20%"
                  stopColor={getGradientTop(props.tileData["categoryId"])}
                />
                <stop
                  offset="80%"
                  stopColor={getGradientBottom(props.tileData["categoryId"])}
                />
              </linearGradient>
            </defs>
          </Wave>
          <ul
            className="bubbles-card"
            style={{
              "--bubble-color": getGradientTop(props.tileData["categoryId"]),
            }}
          >
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </>
      )}
      {movingWave ? pageComponent : null}
    </div>
  );
}

export default Splash;
