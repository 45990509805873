import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { useState } from "react";
import OtpInput from "react-otp-input";
import DrPage from "../display/DrPage.js";
import DrContent from "../display/DrContent.js";
import AppStore from "../components/AppStore.js";
import CacheHelp from "../components/CacheHelp.js";

const isMobile = window.innerWidth < window.innerHeight;
let contentW = "40%";
if (isMobile) {
  contentW = "100vw";
}

const Content = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`;

const InputContainer = styled.div`
  height: 30%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 5%;
`;

const ErrorContainer = styled.div`
  height: 10%;
  width: 100%;
  justify-content: center;
  display: block;
  font-size: 3vh;
  color: var(--accentColor);
  text-shadow: 0 0 5px var(--accentColor);
  font-family: var(--subTitleFont);
  margin-bottom: 10%;
`;

const iconSelectPaths = [
  "game piece-0.png",
  "game piece-01.png",
  "game piece-02.png",
  "game piece-03.png",
  "game piece-04.png",
  "game piece-05.png",
  "game piece-06.png",
  "game piece-07.png",
  "game piece-08.png",
  "game piece-09.png",
  "game piece-10.png",
  "game piece-11.png",
  "portrait4x.png",
];

const iconLobbyPaths = [
  "game piece-0.png",
  "game piece-01.png",
  "game piece-02.png",
  "game piece-03.png",
  "game piece-04.png",
  "game piece-05.png",
  "game piece-06.png",
  "game piece-07.png",
  "game piece-08.png",
  "game piece-09.png",
  "game piece-10.png",
  "game piece-11.png",
  "portrait4x.png",
];
/*
                        width: "55%",
                        height: "5vh",
                        margin: "3vh",
                        fontSize: "3vh",
                        padding: "1%",
                        background: "red",
                        display: "flex",
                        justifyContent: "center"
*/

const drinkRoyaleAlb = "http://3.147.66.6/";

function JoinForm(props) {
  const [otp, setOtp] = useState("");
  const [charRemaining, setCharRemaining] = useState();
  const [floatAway, setFloatAway] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [showCacheHelp, setShowCacheHelp] = useState(false);
  const [ableToJoin, setAbleToJoin] = useState(false);
  useEffect(() => {
    if (props.roomCode && props.urlCode) {
      setOtp(props.roomCode);
    }
  }, [props.roomCode, props.urlCode]);

  useEffect(() => {
    if (otp) {
      props.onRoomCodeChange(otp);
    }
    if (otp && !ableToJoin && props.username) {
      setAbleToJoin(true);
    }
    if (!otp && ableToJoin) {
      setAbleToJoin(false);
    }
  }, [otp]);

  useEffect(() => {
    if (props.username) {
      setCharRemaining(12 - props.username.length);
      if (otp && !ableToJoin) {
        setAbleToJoin(true);
      }
    } else {
      if (ableToJoin) {
        setAbleToJoin(false);
      }
      setCharRemaining(null);
    }
  }, [props.username]);

  useEffect(() => {
    if (props.errorMsg) {
      window.scrollTo(0, 0);
    }
  }, [props.errorMsg]);

  useEffect(() => {
    if (props.floatAway) {
      setFloatAway(true);
    }
  }, [props.floatAway]);

  useEffect(() => {
    window.scrollTo(0, 0);
    preloadImages("/splashes/", ["duel-splash-fast.png"]);
    preloadImages("/thumbnails/", ["duel-thumb.png"]);
    preloadImages("/icons/", iconSelectPaths);
    preloadImages("/icons/", iconLobbyPaths)
      .then(() => {
        console.log("Images preloaded successfully");
        // You can optionally perform any other actions after images are preloaded
      })
      .catch((error) => {
        console.error("Error preloading images:", error);
      });
  }, []);

  const handleFocus = (event) => {
    const { selectionStart, selectionEnd } = event.target;
    if (selectionStart === selectionEnd) {
      event.target.setSelectionRange(selectionStart, selectionStart);
    }
  };

  function preloadImages(dir, paths) {
    return new Promise((resolve, reject) => {
      let loadedCount = 0;
      const totalImages = paths.length;

      const onLoad = () => {
        loadedCount++;
        if (loadedCount === totalImages) {
          resolve();
        }
      };

      paths.forEach((path) => {
        const img = new Image();
        img.src = dir + path;
        img.onload = onLoad;
        img.onerror = onLoad;
      });
    });
  }

  function hostClicked(e) {
    e.preventDefault();
    if (props.paywall) {
      setShowApp(true);
    } else {
      props.create();
    }
  }

  function handleBack(e) {
    e.preventDefault();
    setShowApp(false);
    setShowCacheHelp(false);
  }

  function handleCacheHelp(e) {
    e.preventDefault();
    setShowCacheHelp(true);
  }

  function getErrorMessgae(msg) {
    if (msg.toLowerCase().includes("cache")) {
      setShowCacheHelp(true);
    }
    return msg;
  }

  function renderForm() {
    return (
      <>
        <InputContainer>
          <div
            className="labelContainer"
            style={{
              animation: floatAway ? "floatOff 1.75s 1 linear" : "",
            }}
          >
            <label className="labelText">
              Name:
              <label className="charactersRemaining">{charRemaining}</label>
            </label>

            <input
              id="usernameInput"
              placeholder="ENTER NAME"
              type="text"
              value={props.username}
              onChange={props.onUsernameChange}
              className="formInput"
            />
          </div>
          <div
            className="labelContainer"
            style={{ animation: floatAway ? "floatOff 2s linear 1" : "" }}
          >
            <label className="labelText">Join Code:</label>
            <OtpInput
              value={otp.toUpperCase()}
              onChange={setOtp}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              inputStyle={"formOtpInput"}
              containerStyle={"formOtpContainer"}
              onFocus={handleFocus}
            />
          </div>
        </InputContainer>
        <InputContainer
          style={{
            flexDirection: "column",
            justifyContent: "center",
            animation: floatAway ? "floatOff 2s linear" : "",
          }}
        >
          <button
            id="joinButton"
            className={`formButton ${ableToJoin ? "open" : ""}`}
            style={{ animation: "bob2 3s infinite linear" }}
            onClick={props.connect}
          >
            {" "}
            Join{" "}
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              margin: "5%",
            }}
          >
            <div className="orHostLabel">OR</div>
            <div className="hostLink" onClick={hostClicked}>
              Host your own Game
            </div>
          </div>
        </InputContainer>
        <ErrorContainer
          style={{ animation: floatAway ? "floatOff 2.1s linear" : "" }}
          onClick={handleCacheHelp}
        >
          {props.errorMsg ? getErrorMessgae(props.errorMsg) : ""}
        </ErrorContainer>
      </>
    );
  }

  function renderContent() {
    if (showApp) {
      return <AppStore handleBack={handleBack} />;
    }
    if (showCacheHelp) {
      return <CacheHelp handleBack={handleBack} />;
    }
    return renderForm();
  }
  return (
    <DrPage>
      <DrContent>
        <Content>
          <ul class="bubbles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <img
            className="lobbyLogo "
            height="12.5%"
            width="auto"
            src={"/logo/500px.png"}
            alt={"mainLogo"}
            style={{ animation: floatAway ? "floatOff 2.75s linear 1" : "" }}
          />
          <div
            className="formGameTitle"
            style={{ animation: floatAway ? "floatOff 2.25s linear 1" : "" }}
          >
            {"Drink Royale"}
          </div>
          {renderContent()}
        </Content>
      </DrContent>
    </DrPage>
  );
}

//props.appVersion
//                <button className="formButton" onClick={simulateGame}> SIM </button>

export default JoinForm;
