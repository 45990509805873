import React, { useState, useEffect } from "react";
import styled from "styled-components";

import DrEvent from "../../display/DrEvent";

const Page = styled.div`
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function DelayedPrompt(props) {
  const [count, setCount] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count === 0) {
        clearInterval(interval);
      } else {
        setCount((count) => count - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [count]);
  return (
    <DrEvent className="challengeContainer">
      <div className="horizontalPrompt">
        <span className="rotatedText">
          {count
            ? `Phones up!\n\n${count}`
            : props.tileData.varData[props.yourId]}
        </span>
      </div>

      <>
        {props.yourId === props.turnPlayer || true ? (
          <button className="splashNavBtn" onClick={props.handleNextBtn}>
            {"END TURN"}
          </button>
        ) : null}
      </>
    </DrEvent>
  );
}

export default DelayedPrompt;
