import React, { useEffect, useRef } from "react";
import "../Splash.css";
import ProgressBar from "./ProgressBar";
import InputResult from "./InputResult";
import styled from "styled-components";
import DrPage from "../display/DrPage";
import DrEvent from "../display/DrEvent";

const Page = styled.div`
  backface-visibility: hidden;
  position: absolute;
  top: 4.5%;
  left: 0;
  width: 100vw;
  height: 95%;
  border: 0px solid var(--offBlack);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--offBlack);
  transition: opacity 0.5s ease-in-out;
  z-index: 2;
`;

const playerColors = [
  "#00FFFF",
  "#FF0000",
  "#FFFF00",
  "#99FF00",
  "#339933",
  "#FF9450",
  "#990050",
  "#FF9900",
  "#9966FF",
  "#FFFFFF",
  "#FF99C3",
  "#0066CC",
];

function ResultCard(props) {
  useEffect(() => {
    if (props.closeTriggered) {
      closeResults();
    }
  }, [props.closeTriggered]);

  async function closeResults() {
    if (!props.closeTriggered) {
      //&& props.turnPlayer === props.yourId
      props.sendEvent("end turn", {});
    }
    props.handleClose();
  }

  function handleDone(e) {
    e.preventDefault();
    //const container = document.querySelector('.resultPage');
    //container.style.opacity = 0;
    setTimeout(() => {
      closeResults();
    }, 500);
  }

  function getInputResults() {
    let results = [];
    if (props.socketData["results"]) {
      const valLabel = props.socketData.valLabel
        ? props.socketData.valLabel
        : "s";
      console.log(props.socketData);
      const sortedResults =
        props.socketData.displayType === "DESC"
          ? Object.entries(props.socketData["results"]).sort(
              (a, b) => b[1] - a[1]
            )
          : Object.entries(props.socketData["results"]).sort(
              (a, b) => a[1] - b[1]
            );

      let highestVal = 10000;
      for (let i = 0; i < sortedResults.length; i++) {
        if (sortedResults[i][1] !== 10000) {
          highestVal = sortedResults[i][1];
          break;
        }
      }

      let numResults = 0;
      sortedResults.forEach(([key, val]) => {
        val > 0 ? numResults++ : console.log();
      });

      sortedResults.forEach(([key, val], index) => {
        const player = props.lobby.players.find((u) => u.uid === key);
        if (player) {
          let offset = numResults > 6 ? 3 : numResults / 2;
          let delayVal = index < 6 ? offset - index / 2 : 0;
          val > 0
            ? results.push(
                <InputResult
                  animationTime={500} // Animation time in milliseconds
                  textDelay={delayVal}
                  playerName={player.name}
                  playerVal={val}
                  valLabel={valLabel}
                  isWinner={val === highestVal || val === 10000}
                  playerColor={playerColors[player.iconId]}
                />
              )
            : console.log();
        }
      });
    }
    return results;
  }

  function getResultBars() {
    let results = [];
    const lobby = props.socketData["lobby"];
    if (props.socketData["results"]) {
      const sortedResults = Object.entries(props.socketData["results"]).sort(
        (a, b) => b[1] - a[1]
      );

      const highestVoteCount = sortedResults[0][1];
      let numResults = 0;
      sortedResults.forEach(([key, val]) => {
        val > 0 ? numResults++ : console.log();
      });
      sortedResults.forEach(([key, val], index) => {
        const res = lobby["players"][key]
          ? lobby["players"][key]
          : { name: key };
        let offset = numResults > 6 ? 3 : numResults / 2;
        let delayVal = index < 6 ? offset - index / 2 : 0;
        val > 0
          ? results.push(
              <ProgressBar
                animationTime={500} // Animation time in milliseconds
                textDelay={delayVal}
                stopPercentage={100 * (val / highestVoteCount)} // Percentage at which the bar stops filling
                filledText={`${res.name} [ ${val} ] ${
                  val === highestVoteCount ? " WINNER" : ""
                }`}
                isWinner={val === highestVoteCount}
              />
            )
          : console.log();
      });

      return results;
    }
  }

  /* fallback
                <div className='resultsList'>
                {renderResults()}
            </div>
    */

  //Prepend div container to top of list
  //To start the container just has a left aligned palyer name
  //then the container fills left to right to show
  // votes/highestVotes
  // White fill if not winner
  //orange fill if winner
  const isWordle = props.tileData.title == "Word Race";
  return (
    <div className="resultPage">
      <div className="resultsTitle">{`${props.prize}${
        isWordle ? props.tileData.challengeJson.targetWord : ""
      }`}</div>
      <div className="resultsList">
        {props.socketData.type === "input results"
          ? getInputResults()
          : getResultBars()}
      </div>
      <>
        {props.yourId === props.turnPlayer || true ? (
          <button className="splashNavBtn" onClick={handleDone}>
            END TURN
          </button>
        ) : null}
      </>
    </div>
  );
}

export default ResultCard;
