import React, { useState, useEffect } from "react";
import DrEvent from "../../display/DrEvent";

const iconPaths = [
  "game piece-0.png",
  "game piece-01.png",
  "game piece-02.png",
  "game piece-03.png",
  "game piece-04.png",
  "game piece-05.png",
  "game piece-06.png",
  "game piece-07.png",
  "game piece-08.png",
  "game piece-09.png",
  "game piece-10.png",
  "game piece-11.png",
  "portrait4x.png",
];

const playerColors = [
  "#00FFFF",
  "#FF0000",
  "#FFFF00",
  "#99FF00",
  "#339933",
  "#FF9450",
  "#990050",
  "#FF9900",
  "#9966FF",
  "#FFFFFF",
  "#FF99C3",
  "#0066CC",
];

const playerBackground = [
  "#00FFFF",
  "#FF0000",
  "#FFFF00",
  "#99FF00",
  "#339933",
  "#FF9450",
  "#990050",
  "#FF9900",
  "#9966FF",
  "#FFFFFF",
  "#FF99C3",
  "#0066CC",
];

function WheelChallenge(props) {
  function getPrizeText() {
    if (props.dLvl === 2) {
      return " finishes their drink";
    }
    if (props.sLvl === 2) {
      return " loses an article of clothing";
    }
    if (props.dLvl === 1) {
      return " takes a 5sec drink";
    }
    return " takes a 3sec drink";
  }

  const [prizes] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  const [deg, setDeg] = useState(0);
  const [winIndex, setWinIndex] = useState(-1);
  const [activePlayers, setActivePlayers] = useState(getPlayerOrder());

  useEffect(() => {
    setTimeout(() => {
      spin();
    }, 1500);
  }, []);

  function getPlayerOrder() {
    const activePlayers = props.players.filter(
      (player) => player.connected && !player.waiting
    );
    const validCounts = [1, 2, 3, 4, 6, 12];
    const activePlayerCount = activePlayers.length;

    let sortedPlayers = [];
    if (validCounts.includes(activePlayerCount)) {
      for (let i = 0; i < 12; i++) {
        sortedPlayers.push(activePlayers[i % activePlayerCount]);
      }
    } else {
      const randomStart = Math.floor(props.randomOffset * activePlayerCount);
      for (let i = 0; i < 12; i++) {
        sortedPlayers.push(
          activePlayers[(i + randomStart) % activePlayerCount]
        );
      }
    }
    return sortedPlayers;
  }

  function spin() {
    let spins = props.spinNum;
    console.log("spins: " + spins);

    let wheelAngle = props.randomAngle;
    console.log("wheelAngle: " + wheelAngle);

    let sectorAngle = 0;

    let finalDeg = 360 * spins + wheelAngle + sectorAngle;

    setDeg((deg) => deg + finalDeg);

    let index = -1;

    index = Math.floor((finalDeg % 360) / 30) % 12;
    if (index < 0) index += 12;

    setTimeout(() => setWinIndex(Math.abs(index - 1)), 5100);
  }

  function renderWheel() {
    return (
      <>
        <div className="wheelArrow">↓</div>
        <div className="wheel" style={{ transform: `rotate(${deg}deg)` }}>
          <div className="inner">
            {prizes.map((prize, index) => (
              <div
                className="slice"
                key={index}
                style={{
                  borderColor:
                    index == winIndex
                      ? `${
                          playerBackground[activePlayers[index].iconId]
                        } transparent`
                      : `${index % 2 == 0 ? "#151515" : "black"} transparent`,
                }}
              >
                <span className="prize">
                  <img
                    className="prizeIcon"
                    src={`/icons/${iconPaths[activePlayers[index].iconId]}`}
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
        <img
          className="wheelButton"
          src={`/logo/200px fire.png`}
          style={{ transform: `rotate(${deg}deg)` }}
        />
      </>
    );
  }

  function getTopPrompt() {
    if (winIndex === -1) {
      return "Loser gets a mystery prize";
    } else {
      return `${activePlayers[winIndex].name}${getPrizeText()}`;
    }
  }

  function renderButton() {
    let usable = winIndex !== -1 && (props.yourId === props.turnPlayer || true);
    return (
      <button
        className="splashNavBtn"
        style={{ opacity: usable ? 1 : 0 }}
        onClick={usable ? props.handleClose : null}
      >
        END TURN
      </button>
    );
  }

  return (
    <DrEvent className="challengeContainer">
      <div className="wheelTitleContainer">{getTopPrompt()}</div>
      <div className="wheelWrapper">{renderWheel()}</div>
      {renderButton()}
    </DrEvent>
  );
}

export default WheelChallenge;
