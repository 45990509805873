export function stageTurnData(props, val) {
  //const miniBoard = props.lobby.miniBoard;
  const user = props.lobby.players.find((u) => u.uid === props.yourId);
  let pos = user["position"];
  const proj = pos + val;
  //const lastTileIndex = miniBoard?props.tileMap['miniBoard'].length-1:props.tileMap['bigBoard'].length-1;
  const lastTileIndex = props.tileMap["bigBoard"].length - 1;
  let adjVal = proj > lastTileIndex ? lastTileIndex - pos : val;
  if (proj < 0) {
    adjVal = -1 * pos;
  }
  //const tileSet = miniBoard?props.tileMap['miniBoard']:props.tileMap['bigBoard']
  const tileSet = props.tileMap["bigBoard"];

  const landingTile = tileSet[pos + adjVal];
  const categoryId = landingTile.categoryId;
  let td = getVariedData(props, categoryId, val);
  let payload = {
    val,
    pos,
    tileData: td,
  };
  props.sendEvent("player roll", payload);
}

function getMyPlayer(props) {
  return props.lobby.players.find((u) => u.uid === props.lobby.turnPlayer);
}

function getNextCategoryId(props) {
  const sLvl = props.sLvl;
  const dLvl = props.dLvl;
  /*
    1   Take 
    2   Give 
    3   Drink if *
    4   Solo Challenge 
    5   All Play *
    6   Duel 
    7   Rule *
    8   Hot Seat 
    9   Dare 
    10  Chance
    11  Unlucky
    13  Vote *
    */

  /*

*/

  let order = [
    "3",
    "13",
    "2",
    "5",
    "4",
    "1",
    "3",
    "5",
    "7",
    "4",
    "2",
    "8",
    "13",
    "3",
    "1",
    "4",
    "5",
    "6",
    "3",
    "2",
    "9",
    "4",
    "13",
    "5",
    "1",
    "3",
    "8",
    "2",
    "4",
    "3",
    "13",
    "9",
    "1",
    "5",
    "7",
    "3",
    "13",
    "4",
    "2",
    "9",
    "3",
    "8",
    "5",
    "1",
    "6",
  ];

  // let order = [
  //   "3",
  //   "13",
  //   "4",
  //   "2",
  //   "5",
  //   "1",
  //   "7",
  //   "8",
  //   "3",
  //   "4",
  //   "13",
  //   "9",
  //   "2",
  //   "5",
  //   "1",
  //   "6",
  //   "3",
  //   "8",
  //   "4",
  //   "13",
  //   "2",
  //   "5",
  //   "1",
  //   "9",
  //   "10",
  //   "13",
  //   "4",
  //   "7",
  //   "3",
  //   "1",
  //   "5",
  //   "2",
  //   "6",
  //   "13",
  //   "4",
  //   "8",
  // ];
  if (sLvl === 1 && dLvl === 1) {
    // order = [
    //   "3",
    //   "13",
    //   "4",
    //   "2",
    //   "5",
    //   "1",
    //   "7",
    //   "8",
    //   "3",
    //   "4",
    //   "13",
    //   "9",
    //   "2",
    //   "5",
    //   "1",
    //   "6",
    //   "3",
    //   "8",
    //   "4",
    //   "13",
    //   "2",
    //   "5",
    //   "1",
    //   "9",
    //   "10",
    //   "13",
    //   "4",
    //   "7",
    //   "3",
    //   "1",
    //   "5",
    //   "2",
    //   "6",
    //   "13",
    //   "4",
    //   "8",
    //   "3",
    //   "9",
    //   "11",
    // ];
    order = [
      "3",
      "13",
      "2",
      "4",
      "5",
      "1",
      "2",
      "3",
      "7",
      "4",
      "5",
      "13",
      "1",
      "2",
      "9",
      "4",
      "5",
      "3",
      "2",
      "13",
      "8",
      "10",
      "5",
      "6",
      "4",
      "1",
      "2",
      "3",
      "13",
      "5",
      "9",
      "4",
      "1",
      "3",
      "7",
      "5",
      "8",
      "13",
      "9",
      "2",
      "3",
      "4",
      "3",
      "2",
      "1",
      "13",
      "5",
      "8",
      "9",
      "6",
      "11",
    ];
  }
  if (sLvl === 0 && dLvl === 1) {
    // order = [
    //   "3",
    //   "13",
    //   "2",
    //   "4",
    //   "5",
    //   "1",
    //   "7",
    //   "3",
    //   "4",
    //   "2",
    //   "8",
    //   "5",
    //   "6",
    //   "13",
    //   "1",
    //   "10",
    //   "4",
    //   "3",
    //   "2",
    //   "5",
    //   "1",
    //   "13",
    //   "8",
    //   "4",
    //   "3",
    //   "9",
    //   "2",
    //   "5",
    //   "7",
    //   "1",
    //   "13",
    //   "6",
    //   "4",
    //   "3",
    //   "8",
    //   "2",
    //   "1",
    //   "5",
    //   "9",
    //   "11",
    // ];
    order = [
      "3",
      "13",
      "2",
      "4",
      "5",
      "1",
      "3",
      "5",
      "2",
      "4",
      "7",
      "13",
      "1",
      "3",
      "5",
      "10",
      "2",
      "6",
      "4",
      "8",
      "9",
      "3",
      "5",
      "13",
      "2",
      "4",
      "8",
      "3",
      "1",
      "6",
      "5",
      "2",
      "13",
      "7",
      "4",
      "3",
      "5",
      "2",
      "9",
      "1",
      "4",
      "3",
      "5",
      "13",
      "8",
      "2",
      "4",
      "3",
      "1",
      "11",
    ];
  }
  if (sLvl === 0 && dLvl === 2) {
    // order = [
    //   "3",
    //   "13",
    //   "5",
    //   "1",
    //   "2",
    //   "4",
    //   "3",
    //   "7",
    //   "13",
    //   "5",
    //   "8",
    //   "2",
    //   "1",
    //   "4",
    //   "6",
    //   "3",
    //   "2",
    //   "9",
    //   "5",
    //   "1",
    //   "10",
    //   "13",
    //   "4",
    //   "2",
    //   "3",
    //   "5",
    //   "9",
    //   "1",
    //   "3",
    //   "8",
    //   "6",
    //   "7",
    //   "13",
    //   "2",
    //   "4",
    //   "5",
    //   "1",
    //   "3",
    //   "2",
    //   "8",
    //   "13",
    //   "2",
    //   "4",
    //   "9",
    //   "11",
    // ];
    order = [
      "3",
      "13",
      "2",
      "4",
      "5",
      "3",
      "1",
      "2",
      "5",
      "4",
      "7",
      "1",
      "3",
      "13",
      "2",
      "5",
      "10",
      "4",
      "3",
      "6",
      "1",
      "8",
      "5",
      "13",
      "4",
      "2",
      "3",
      "9",
      "5",
      "7",
      "3",
      "4",
      "13",
      "5",
      "10",
      "2",
      "8",
      "3",
      "4",
      "1",
      "2",
      "9",
      "13",
      "3",
      "5",
      "1",
      "2",
      "6",
      "4",
      "11",
    ];
  }
  if (sLvl === 1 && dLvl === 0) {
    // order = [
    //   "3",
    //   "13",
    //   "4",
    //   "1",
    //   "5",
    //   "8",
    //   "2",
    //   "3",
    //   "7",
    //   "4",
    //   "13",
    //   "5",
    //   "1",
    //   "8",
    //   "3",
    //   "9",
    //   "2",
    //   "4",
    //   "13",
    //   "6",
    //   "3",
    //   "1",
    //   "5",
    //   "9",
    //   "2",
    //   "8",
    //   "13",
    //   "4",
    //   "1",
    //   "3",
    //   "5",
    //   "2",
    //   "13",
    //   "7",
    //   "9",
    //   "8",
    //   "3",
    //   "1",
    //   "4",
    //   "6",
    //   "13",
    //   "5",
    //   "9",
    //   "2",
    // ];
    order = [
      "3",
      "13",
      "2",
      "4",
      "5",
      "8",
      "1",
      "3",
      "5",
      "2",
      "7",
      "13",
      "4",
      "3",
      "5",
      "9",
      "8",
      "6",
      "13",
      "2",
      "3",
      "1",
      "4",
      "5",
      "9",
      "2",
      "8",
      "13",
      "3",
      "4",
      "5",
      "9",
      "2",
      "7",
      "8",
      "13",
      "3",
      "4",
      "5",
      "1",
      "2",
      "3",
      "13",
      "8",
      "9",
      "4",
      "5",
      "3",
      "1",
      "9",
    ];
  }
  if (sLvl === 1 && dLvl === 2) {
    // order = [
    //   "3",
    //   "13",
    //   "4",
    //   "2",
    //   "5",
    //   "1",
    //   "3",
    //   "7",
    //   "4",
    //   "13",
    //   "8",
    //   "2",
    //   "5",
    //   "1",
    //   "3",
    //   "2",
    //   "10",
    //   "13",
    //   "9",
    //   "4",
    //   "5",
    //   "2",
    //   "6",
    //   "8",
    //   "3",
    //   "1",
    //   "13",
    //   "2",
    //   "4",
    //   "9",
    //   "5",
    //   "1",
    //   "7",
    //   "3",
    //   "8",
    //   "2",
    //   "13",
    //   "4",
    //   "9",
    //   "5",
    //   "1",
    //   "3",
    //   "6",
    //   "8",
    //   "13",
    //   "4",
    //   "2",
    //   "9",
    //   "11",
    // ];

    order = [
      "3",
      "13",
      "2",
      "4",
      "5",
      "1",
      "2",
      "5",
      "3",
      "8",
      "7",
      "4",
      "2",
      "13",
      "10",
      "1",
      "9",
      "5",
      "3",
      "6",
      "4",
      "2",
      "13",
      "8",
      "9",
      "5",
      "3",
      "1",
      "4",
      "2",
      "6",
      "13",
      "3",
      "5",
      "7",
      "1",
      "4",
      "9",
      "2",
      "3",
      "5",
      "8",
      "13",
      "1",
      "4",
      "10",
      "3",
      "9",
      "2",
      "11",
    ];
  }
  if (sLvl === 2 && dLvl === 0) {
    // order = [
    //   "3",
    //   "13",
    //   "4",
    //   "2",
    //   "5",
    //   "1",
    //   "8",
    //   "3",
    //   "7",
    //   "13",
    //   "2",
    //   "4",
    //   "9",
    //   "5",
    //   "8",
    //   "3",
    //   "1",
    //   "2",
    //   "13",
    //   "4",
    //   "6",
    //   "9",
    //   "5",
    //   "8",
    //   "3",
    //   "2",
    //   "13",
    //   "4",
    //   "1",
    //   "3",
    //   "9",
    //   "5",
    //   "8",
    //   "2",
    //   "13",
    //   "4",
    //   "3",
    //   "1",
    //   "8",
    //   "5",
    //   "9",
    //   "2",
    //   "3",
    //   "1",
    //   "13",
    //   "4",
    //   "9",
    // ];
    order = [
      "3",
      "13",
      "2",
      "5",
      "4",
      "8",
      "1",
      "3",
      "5",
      "4",
      "9",
      "7",
      "2",
      "13",
      "3",
      "5",
      "6",
      "4",
      "8",
      "13",
      "9",
      "3",
      "8",
      "4",
      "13",
      "5",
      "8",
      "9",
      "3",
      "4",
      "1",
      "13",
      "5",
      "8",
      "9",
      "3",
      "4",
      "2",
      "9",
      "13",
      "5",
      "8",
      "2",
      "9",
      "3",
      "4",
      "1",
      "3",
      "9",
      "13",
    ];
  }
  if (sLvl === 2 && dLvl === 1) {
    // order = [
    //   "3",
    //   "13",
    //   "4",
    //   "2",
    //   "5",
    //   "1",
    //   "7",
    //   "3",
    //   "8",
    //   "2",
    //   "13",
    //   "4",
    //   "9",
    //   "5",
    //   "1",
    //   "2",
    //   "3",
    //   "10",
    //   "8",
    //   "13",
    //   "6",
    //   "4",
    //   "2",
    //   "5",
    //   "1",
    //   "11",
    //   "9",
    //   "8",
    //   "3",
    //   "4",
    //   "13",
    //   "2",
    //   "7",
    //   "5",
    //   "8",
    //   "9",
    //   "3",
    //   "4",
    //   "1",
    //   "13",
    //   "2",
    //   "6",
    //   "5",
    //   "8",
    //   "9",
    //   "3",
    //   "4",
    //   "1",
    //   "5",
    //   "11",
    // ];
    order = [
      "3",
      "13",
      "2",
      "5",
      "4",
      "8",
      "1",
      "3",
      "5",
      "4",
      "9",
      "7",
      "2",
      "13",
      "3",
      "10",
      "5",
      "6",
      "4",
      "8",
      "13",
      "9",
      "3",
      "8",
      "4",
      "13",
      "5",
      "1",
      "9",
      "3",
      "4",
      "1",
      "13",
      "5",
      "8",
      "9",
      "3",
      "4",
      "2",
      "1",
      "13",
      "5",
      "8",
      "2",
      "9",
      "3",
      "4",
      "1",
      "2",
      "9",
      "11",
    ];
  }
  if (sLvl === 2 && dLvl === 2) {
    // order = [
    //   "3",
    //   "13",
    //   "2",
    //   "5",
    //   "4",
    //   "1",
    //   "2",
    //   "7",
    //   "3",
    //   "8",
    //   "5",
    //   "2",
    //   "13",
    //   "4",
    //   "10",
    //   "1",
    //   "3",
    //   "8",
    //   "2",
    //   "13",
    //   "9",
    //   "4",
    //   "11",
    //   "5",
    //   "1",
    //   "6",
    //   "3",
    //   "2",
    //   "9",
    //   "8",
    //   "13",
    //   "4",
    //   "5",
    //   "2",
    //   "1",
    //   "9",
    //   "3",
    //   "8",
    //   "4",
    //   "13",
    //   "2",
    //   "7",
    //   "9",
    //   "5",
    //   "1",
    //   "3",
    //   "8",
    //   "2",
    //   "10",
    //   "3",
    //   "6",
    //   "4",
    //   "9",
    //   "13",
    //   "1",
    //   "5",
    //   "8",
    //   "11",
    // ];
    order = [
      "3",
      "13",
      "2",
      "5",
      "4",
      "8",
      "1",
      "5",
      "3",
      "9",
      "7",
      "4",
      "2",
      "3",
      "5",
      "10",
      "13",
      "1",
      "8",
      "6",
      "11",
      "3",
      "5",
      "4",
      "2",
      "9",
      "1",
      "8",
      "13",
      "4",
      "2",
      "9",
      "5",
      "3",
      "13",
      "1",
      "8",
      "4",
      "9",
      "5",
      "3",
      "7",
      "2",
      "6",
      "13",
      "9",
      "8",
      "4",
      "3",
      "1",
      "5",
      "2",
      "13",
      "3",
      "9",
      "4",
      "8",
      "5",
      "3",
      "9",
      "11",
    ];
  }
  //order = ["7"];
  return order[props.lobby.turnNum % order.length];
}

export function stageInfiniteTurnData(props) {
  let categoryId = getNextCategoryId(props);
  if (
    props.lobby.backupHost === getMyPlayer(props).name &&
    props.lobby.target &&
    props.lobby.target.hasOwnProperty("4") &&
    props.lobby.target["4"].length
  ) {
    categoryId = "4";
  }
  //categoryId = '11'
  let targetedTitle = "";
  if (props.lobby.turnNum > 5) {
    if (props.lobby.spotlight.hasOwnProperty(categoryId)) {
      targetedTitle = props.lobby.spotlight[categoryId].pop();
      if (!notStaleTile(props, targetedTitle, categoryId)) {
        targetedTitle = "";
      }
    }
  }

  if (props.lobby.backupHost === getMyPlayer(props).name) {
    if (props.lobby.target && props.lobby.target.hasOwnProperty(categoryId)) {
      targetedTitle = props.lobby.target[categoryId].pop();
    }
  }

  let td = getVariedData(props, categoryId, 0, targetedTitle);
  let payload = {
    tileData: td,
  };
  props.sendEvent("start minigame", payload);
}

function randomHueHex() {
  // Generate a random hue value between 0 and 360
  const hue = Math.floor(Math.random() * 361);

  // Convert HSL to RGB
  const rgb = hslToRgb(hue, 100, 50);

  // Convert RGB to Hex
  const hex = rgbToHex(rgb[0], rgb[1], rgb[2]);

  return hex;
}

function hslToRgb(h, s, l) {
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
}

function rgbToHex(r, g, b) {
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

function cleanPrompt(parentObj, field, name) {
  if (parentObj && parentObj.hasOwnProperty(field) && parentObj[field]) {
    //let prompt = parentObj[field].replace(/<name>/g,name)
    let prompt = parentObj[field];
    return prompt.replace(/<br>/g, "\n\n");
  }
}

function getRandObj(arr) {
  const timestamp = new Date().getTime();

  // Use the timestamp as a seed for generating a pseudorandom index
  const seed = timestamp % arr.length;

  // Access the pseudorandomly chosen element
  const randomElement = arr[seed];
  return randomElement;
}

function getRandomChoices(props, numChoices, category) {
  let choices = [];
  let i = 0;
  while (i < numChoices) {
    let choice = getRandObj(props.library.variableData[category]);
    while (choices.includes(choice)) {
      choice = getRandObj(props.library.variableData[category]);
    }
    choices.push(choice);
    i++;
  }
  return choices;
}

function validLvl(props, event, categoryId) {
  let dLvl = 0;
  let sLvl = -1;
  if (event.hasOwnProperty("dLvl")) {
    dLvl = event["dLvl"];
  }
  if (event.hasOwnProperty("sLvl")) {
    sLvl = event["sLvl"];
  }
  if (event.hasOwnProperty("boardExclusive") && props.lobby.miniBoard) {
    return false;
  }
  if (
    event.hasOwnProperty("playerLimit") ||
    event.hasOwnProperty("playerMin")
  ) {
    const maxPlayers = event["playerLimit"];
    const playerMin = event["playerMin"];

    const activePlayers = props.lobby.players.filter(
      (player) => player.connected && !player.waiting
    );
    const numPlayers = activePlayers.length;
    if (numPlayers > maxPlayers || numPlayers < playerMin) {
      return false;
    }
  }
  //Anything with a 0 gets included, no spice level gets left out ONLY SPICE
  if (props.lobby.sLvl === 2) {
    return dLvl <= props.lobby.dLvl && [0, 1, 2].includes(sLvl);
  }
  return dLvl <= props.lobby.dLvl && sLvl <= props.lobby.sLvl;
}

function notStalePrompt(props, prompt, categoryId) {
  let promptData = prompt;
  if (typeof prompt === "object" && prompt.hasOwnProperty("prompt")) {
    promptData = prompt.prompt;
  }
  const promptString = JSON.stringify(promptData);
  const isAlreadyInHistory = props.lobby.promptHistory[categoryId].prompts.some(
    (item) => JSON.stringify(item) === promptString
  );
  return !isAlreadyInHistory;
}

function notStaleTile(props, title, categoryId) {
  return !props.lobby.tileHistory[categoryId].tiles.includes(title);
}

function getEventOptions(props, category, numOptions, categoryId) {
  let dev = false;
  let arr = props.library[category];
  let result = [];

  if (dev) {
    result.push(arr[0]);
    result.push(arr[0]);
    result.push(arr[0]);
    return result;
  }
  numOptions = 1;
  if (categoryId === "11") {
    numOptions = 3;
  }

  const indices = new Set();
  let tries = 0;
  while (indices.size < numOptions && tries < 7000) {
    const index = Math.floor(Math.random() * arr.length);
    if (
      !indices.has(index) &&
      validLvl(props, arr[index], categoryId) &&
      (notStaleTile(props, arr[index].title, categoryId) || tries > 6000)
    ) {
      indices.add(index);
      result.push(arr[index]);
    }
    tries++;
  }
  return result;
}

function getTargetedEvent(props, category, targetTitle) {
  let arr = props.library[category];

  let result = arr.find((event) => event.title === targetTitle);

  return [result];
}

/*
    '1'://Take
    '2'://Give,
    '3'://drinkif
    '4'://Challenge
    '5'://AllPlay
    '6'://Duel
    '7'://Rule
    '8'://Hot Seat
    '9'://Dare
    '10'://Chance
    '11'://badluck
*/

function getPos(player, val, props) {
  if (player["uid"] === props.yourId) {
    return player["position"] + val;
  }
  return player["position"];
}

/*
    p : picking Events
    r : rules
    c : challenge
    v : voting 
    o : outcome
    w : waiting
    a : auto-send
    e : electing (democracy)
*/

function getFavoredPrompt(title) {
  switch (title) {
    case "Charades":
      return "Blow Up Doll";
    case "Fast Money":
      return "fetishes/kinks";
    case "Truth":
      return "what is the most questionable thing you've masturbated to";
    case "Paranoia":
      return "Which two players would you have a threesome with";
  }
  return false;
}

function getVariedData(props, categoryId, val, targetedTitle) {
  //const categoryId = getRandObj(['1','1','2','2','2','3','3','4','4','4','4','5','5','5','6','6','7','7','7','8','8','8','9','14'])
  //const categoryId = getRandObj(['1','2','3','4','5','6','7','8']);
  //const categoryId = getRandObj(['5']);
  let category;
  let selectionTitle;
  let selectionRules;
  let numOptions = 1;
  let voteOptions;
  let recieveMsg;
  let groupElect;
  let voteType;
  let alertPayload;
  let selectionDeckExp;
  let votePrompt;
  let varData;

  if (categoryId === "2") {
    category = "give";
    let eventOptions = getEventOptions(props, category, numOptions, categoryId);
    if (targetedTitle) {
      eventOptions = getTargetedEvent(props, category, targetedTitle);
    }
    let eventSelection = getRandObj(eventOptions);
    selectionTitle = eventSelection["title"];
    selectionDeckExp = eventSelection["dexp"];
    recieveMsg = eventSelection["recieveMsg"];

    if (selectionDeckExp.includes("r")) {
      //Rules
      let turnPlayerName = getMyPlayer(props).name;
      selectionRules = cleanPrompt(eventSelection, "rules", turnPlayerName);
      if (eventSelection.hasOwnProperty("varData") && selectionDeckExp == "r") {
        varData = getRandObj(
          props.library["variableData"][selectionTitle.toLowerCase()]
        );
        if (props.lobby.backupHost === turnPlayerName) {
          let favoredPrompt = getFavoredPrompt(selectionTitle);
          if (favoredPrompt) {
            varData = favoredPrompt;
          }
        }
        let i = 0;
        while (!notStalePrompt(props, varData, categoryId) && i < 1500) {
          varData = getRandObj(
            props.library["variableData"][selectionTitle.toLowerCase()]
          );
          i++;
        }
      }
    }

    if (selectionDeckExp.includes("v")) {
      //Picking screen
      let voteSet = eventSelection["voteSet"];
      if (eventSelection.prompt) {
        varData = eventSelection.noVarData
          ? ""
          : getRandObj(
              props.library["variableData"][selectionTitle.toLowerCase()]
            );
        while (!notStalePrompt(props, varData, categoryId)) {
          varData = getRandObj(
            props.library["variableData"][selectionTitle.toLowerCase()]
          );
        }
        if (typeof varData === "object") {
          votePrompt = eventSelection["prompt"] + varData["prompt"];
        } else {
          votePrompt = eventSelection["prompt"] + varData;
        }
      }
      if (voteSet === "players") {
        voteOptions = eventSelection.noRandom
          ? []
          : [{ id: "Random", name: "Random" }];
        for (const [id, player] of Object.entries(props.lobby["players"])) {
          if (player["connected"]) {
            voteOptions.push({
              id: id,
              name: player["name"],
            });
          }
        }
      } else if (voteSet === "var") {
        voteOptions = varData["voteSet"];
      }
    }
    if (selectionDeckExp.includes("a")) {
      //autoGive
      let giveType = eventSelection["giveType"];
      let giveList = [];

      let yourPosition = getPos(getMyPlayer(props), val, props);
      switch (giveType) {
        case "behind":
          for (const player of props.lobby["players"]) {
            if (
              player["connected"] &&
              player["uid"] !== props.yourId &&
              getPos(player, val, props) <= yourPosition
            ) {
              giveList.push(player["uid"]);
            }
          }
          break;
        case "first":
          let highestPosition = -1;
          for (const player of props.lobby["players"]) {
            if (
              player["connected"] &&
              getPos(player, val, props) > highestPosition
            ) {
              highestPosition = getPos(player, val, props);
            }
          }
          for (const player of props.lobby["players"]) {
            if (
              player["connected"] &&
              getPos(player, val, props) === highestPosition
            ) {
              giveList.push(player["uid"]);
            }
          }
          break;
        case "last":
          let lowestPosition = 99999;
          for (const player of props.lobby["players"]) {
            if (
              player["connected"] &&
              getPos(player, val, props) < lowestPosition
            ) {
              lowestPosition = getPos(player, val, props);
            }
          }
          for (const player of props.lobby["players"]) {
            if (
              player["connected"] &&
              getPos(player, val, props) === lowestPosition
            ) {
              giveList.push(player["uid"]);
            }
          }
          break;
      }
      const alertData = {
        userList: giveList,
        msg: "You recieved a " + recieveMsg,
      };
      if (giveList.length) {
        alertPayload = alertData;
      }
    }
    if (selectionDeckExp.includes("e")) {
      //Electing
      groupElect = true;
      voteType = eventSelection["voteType"];
    }

    let payload = { ...eventSelection };

    payload["selectionTitle"] = selectionTitle;
    payload["selectionRules"] = selectionRules;
    payload["eventOptions"] = eventOptions;
    payload["categoryId"] = categoryId;
    payload["voteOptions"] = voteOptions;
    payload["groupElect"] = groupElect;
    payload["alertPayload"] = alertPayload;
    payload["votePrompt"] = votePrompt;
    payload["varData"] = varData;

    return payload;
  } else if (categoryId === "4") {
    category = "challenge";
    let eventOptions = getEventOptions(props, category, numOptions, categoryId);
    if (targetedTitle) {
      eventOptions = getTargetedEvent(props, category, targetedTitle);
    }
    let eventSelection = getRandObj(eventOptions);
    let challengeJson;
    let selectionTitle = eventSelection["title"];
    let selectionRules = eventSelection["rules"];
    let turnPlayerName = getMyPlayer(props).name;

    if (eventSelection["style"]) {
      //Generate random not stale data
      let libraryAccessor =
        props.library["variableData"][selectionTitle.toLowerCase()];
      if (
        eventSelection.hasOwnProperty("sLvlSwap") &&
        props.lobby.sLvl >= eventSelection["sLvlSwap"]
      ) {
        libraryAccessor =
          props.library["variableData"][selectionTitle.toLowerCase() + "-slvl"];
      }
      let varData;
      if (!eventSelection.noVarData) {
        varData = getRandObj(libraryAccessor);
        if (props.lobby.backupHost === turnPlayerName) {
          let favoredPrompt = getFavoredPrompt(selectionTitle);
          if (favoredPrompt) {
            varData = favoredPrompt;
          }
        }
        while (!notStalePrompt(props, varData, categoryId)) {
          varData = getRandObj(libraryAccessor);
        }
        if (typeof varData === "object" && varData.prompt) {
          varData["prompt"] = cleanPrompt(varData, "prompt", turnPlayerName);
        }
      }
      if (eventSelection["style"] === "mines") {
        const numMines = 16;
        const numBombs = 2;
        let bombIndex = [];
        while (bombIndex.length < numBombs) {
          let curNum = Math.floor(Math.random() * numMines);
          while (bombIndex.includes(curNum)) {
            curNum = Math.floor(Math.random() * numMines);
          }
          bombIndex.push(curNum);
        }
        varData = bombIndex;
      }
      eventSelection["penalty"] = cleanPrompt(
        eventSelection,
        "penalty",
        turnPlayerName
      );
      eventSelection["prompt"] = cleanPrompt(
        eventSelection,
        "prompt",
        turnPlayerName
      );

      challengeJson = eventSelection;
      challengeJson["varData"] = varData;
    }
    if (eventSelection["rules"]) {
      selectionRules = cleanPrompt(eventSelection, "rules", turnPlayerName);
    }

    //Skip event picking, idk
    let payload = { ...eventSelection };
    payload["selectionTitle"] = selectionTitle;
    payload["eventOptions"] = eventOptions;
    payload["selectionRules"] = selectionRules;
    payload["categoryId"] = categoryId;
    payload["challengeJson"] = challengeJson;
    return payload;
  } else if (categoryId === "5") {
    category = "allplay";
    let eventOptions = getEventOptions(props, category, numOptions, categoryId);
    if (targetedTitle) {
      eventOptions = getTargetedEvent(props, category, targetedTitle);
    }
    let eventSelection = getRandObj(eventOptions);

    let selectionTitle = eventSelection["title"];
    let selectionRules = eventSelection["rules"];
    let turnPlayerName = getMyPlayer(props).name;

    if (eventSelection["rules"]) {
      selectionRules = cleanPrompt(eventSelection, "rules", turnPlayerName);
    }
    let varData;
    if (eventSelection.hasOwnProperty("varData")) {
      let libraryAccessor =
        props.library["variableData"][selectionTitle.toLowerCase()];
      varData = getRandObj(libraryAccessor);
      while (!notStalePrompt(props, varData, categoryId)) {
        varData = getRandObj(libraryAccessor);
      }
      selectionRules += varData.replace(/<br>/g, "\n\n");
    }
    if (eventSelection.hasOwnProperty("uniquePrompts")) {
      const playerAssignments = {};
      const identities = getRandomChoices(
        props,
        12,
        selectionTitle.toLowerCase()
      );
      props.lobby.players.forEach((player) => {
        if (player.connected === true && player.waiting === false) {
          playerAssignments[player.uid] = identities
            .pop()
            .replace(/<br>/g, "\n");
        }
      });
      varData = playerAssignments;
    }
    if (eventSelection.hasOwnProperty("promptPairs")) {
      //let activePlayers = connected and not waiting
      const activePlayers = props.lobby.players.filter(
        (player) => player.connected && !player.waiting
      );
      const promptsNeeded = Math.ceil(activePlayers.length / 2);
      const promptList = getRandomChoices(
        props,
        promptsNeeded,
        selectionTitle.toLowerCase()
      ); // a promptObj is an array where a[0] is the prompt, a[1] is the bot answer
      const payload = {
        promptList,
        promptAssignments: {},
      };
      for (let i = 0; i < promptList.length; i++) {
        if (!activePlayers || activePlayers.length < 1) {
          break;
        }
        const index1 = Math.floor(Math.random() * activePlayers.length);
        const player1 = activePlayers.splice(index1, 1)[0]; // Remove and get the first randomly selected player
        let player2 = null;
        if (activePlayers.length > 0) {
          const index2 = Math.floor(Math.random() * activePlayers.length);
          player2 = activePlayers.splice(index2, 1)[0]; // Remove and get the second randomly selected player
        }

        payload.promptAssignments[i] = {
          player1: player1 ? player1.uid : "bot",
          player2: player2 ? player2.uid : "bot",
        };
      }
      varData = payload;
    }
    if (
      eventSelection.hasOwnProperty("style") &&
      eventSelection["style"] === "rocket"
    ) {
      const randomNumber = Math.random();
      const scaledNumber = randomNumber * 9 + 4;
      eventSelection.challengeJson.crashNum = Math.floor(scaledNumber);
    }
    if (
      eventSelection.hasOwnProperty("style") &&
      eventSelection["style"] === "roulette"
    ) {
      let order = [];
      for (let i = 0; i < 15; i++) {
        const randomNumber = Math.floor(Math.random() * 3);
        order.push(randomNumber);
      }
      eventSelection.challengeJson.order = order;
    }

    if (
      eventSelection.hasOwnProperty("style") &&
      eventSelection["style"] === "wheel"
    ) {
      const randomNumber = Math.floor(Math.random() * 7) + 9;
      const randomOffset = Math.random();
      const randomAngle = Math.floor(Math.random() * 11 + 1) * 30;
      eventSelection.challengeJson.spinNum = randomNumber;
      eventSelection.challengeJson.randomOffset = randomOffset;
      eventSelection.challengeJson.randomAngle = randomAngle;
    }
    if (
      eventSelection.hasOwnProperty("style") &&
      eventSelection["style"] === "color"
    ) {
      eventSelection.challengeJson.targetHex = randomHueHex();
    }
    if (
      eventSelection.hasOwnProperty("style") &&
      eventSelection["style"] === "wordle"
    ) {
      let libraryAccessor =
        props.library["variableData"][selectionTitle.toLowerCase()];
      let word = getRandObj(libraryAccessor);
      eventSelection.challengeJson.targetWord = word;
    }
    if (
      eventSelection.hasOwnProperty("style") &&
      eventSelection["style"] === "ispy"
    ) {
      const targetIndex = Math.floor(Math.random() * 68) + 8;
      const sheetNum = Math.floor(Math.random() * 5);
      const emojiOptions = ["🐢", "🍎", "💎", "🤙", "🍊"];
      const targetEmoji = emojiOptions[sheetNum];
      eventSelection.challengeJson.targetIndex = targetIndex;
      eventSelection.challengeJson.targetEmoji = targetEmoji;
      eventSelection.challengeJson.sheetNum = sheetNum;
    }
    if (
      eventSelection.hasOwnProperty("style") &&
      eventSelection["style"] === "hero"
    ) {
      let grid = [];
      for (let i = 0; i < 50; i++) {
        let curNum = Math.floor(Math.random() * 5);
        grid.push(curNum);
      }
      eventSelection.challengeJson.iconGrid = grid;
    }
    if (
      eventSelection.hasOwnProperty("style") &&
      eventSelection["style"] === "poker"
    ) {
      const card1 = 5 + Math.floor(Math.random() * 5);
      let card2 = 2 + Math.floor(Math.random() * 12);
      while (card2 === card1) {
        card2 = 2 + Math.floor(Math.random() * 12);
      }
      varData = [card1, card2];
    }

    let payload = { ...eventSelection };
    payload["selectionTitle"] = selectionTitle;
    payload["selectionRules"] = selectionRules;
    payload["eventOptions"] = eventOptions;
    payload["categoryId"] = categoryId;
    payload["varData"] = varData;

    return payload;
  } else if (categoryId === "10") {
    category = "chance";
    let eventOptions = getEventOptions(props, category, numOptions, categoryId);
    let eventSelection = getRandObj(eventOptions);
    let selectionTitle = eventSelection["title"];
    let payload = { ...eventSelection };
    if (selectionTitle === "chance") {
      let playerList = [];
      for (const player of props.lobby["players"]) {
        if (player["connected"]) {
          playerList.push({
            id: player.uid,
            name: player.name,
          });
        }
      }
      payload["chanceList"] =
        props.library["variableData"]["chance"][
          props.miniBoard ? "noBoard" : "board"
        ];
      payload["playerList"] = playerList;
    } else {
      let turnPlayerName = getMyPlayer(props).name;
      payload["selectionRules"] = cleanPrompt(
        eventSelection,
        "rules",
        turnPlayerName
      );
      eventSelection.challengeJson.coinFlip = Math.random() < 0.5 ? 0 : 1;
    }

    payload["selectionTitle"] = selectionTitle;
    payload["categoryId"] = categoryId;
    return payload;
  } else if (categoryId === "12") {
    category = "gameover";
    selectionTitle = "Game Over";

    let payload = { ...props.library["gameover"][0] };
    payload["selectionTitle"] = selectionTitle;
    //payload['eventOptions'] = eventOptions required?
    payload["categoryId"] = categoryId;

    return payload;
  } else if (
    ["1", "3", "5", "6", "7", "8", "9", "11", "13"].includes(categoryId)
  ) {
    const nameMap = {
      1: "take",
      3: "drinkif",
      6: "duel",
      7: "rule",
      8: "hotseat",
      9: "dare",
      11: "unlucky",
      13: "vote",
    };
    const category = nameMap[categoryId];
    let eventOptions = getEventOptions(props, category, numOptions, categoryId);
    if (targetedTitle) {
      eventOptions = getTargetedEvent(props, category, targetedTitle);
    }
    let eventSelection = getRandObj(eventOptions);
    selectionTitle = eventSelection["title"];

    //Clean general rules
    let turnPlayerName = getMyPlayer(props).name;
    selectionRules = cleanPrompt(eventSelection, "rules", turnPlayerName);

    let varData;
    if (eventSelection.hasOwnProperty("varData")) {
      let libraryAccessor =
        props.library["variableData"][selectionTitle.toLowerCase()];
      if (
        eventSelection.hasOwnProperty("sLvlSwap") &&
        props.lobby.sLvl >= eventSelection["sLvlSwap"]
      ) {
        libraryAccessor =
          props.library["variableData"][selectionTitle.toLowerCase() + "-slvl"];
      }
      varData = getRandObj(libraryAccessor);
      if (props.lobby.backupHost === turnPlayerName) {
        let favoredPrompt = getFavoredPrompt(selectionTitle);
        if (favoredPrompt) {
          varData = favoredPrompt;
        }
      }
      while (!notStalePrompt(props, varData, categoryId)) {
        varData = getRandObj(libraryAccessor);
      }
      if (
        !eventSelection.hasOwnProperty("list") &&
        !eventSelection.hasOwnProperty("secretPrompt")
      ) {
        if (typeof varData === "string") {
          if (categoryId === "3") {
            if (varData.includes("<br>")) {
              selectionRules = "• " + selectionRules;
            }
            selectionRules += varData.replace(/<br>/g, "\n\n");
          } else {
            selectionRules = selectionRules + varData;
          }
        }
      }
      if (
        eventSelection.hasOwnProperty("list") &&
        varData.hasOwnProperty("prompt")
      ) {
        selectionRules = selectionRules + varData["prompt"];
      }
    }

    selectionDeckExp = eventSelection["dexp"];
    voteType = eventSelection["voteType"];
    if (selectionDeckExp && selectionDeckExp.includes("v")) {
      //Picking screen
      let voteSet = eventSelection["voteSet"];
      if (eventSelection.prompt) {
        if (
          eventSelection.hasOwnProperty("sLvlSwap") &&
          props.lobby.sLvl >= eventSelection["sLvlSwap"]
        ) {
          varData = eventSelection.noVarData
            ? ""
            : getRandObj(
                props.library["variableData"][
                  selectionTitle.toLowerCase() + "-slvl"
                ]
              );
        } else {
          varData = eventSelection.noVarData
            ? ""
            : getRandObj(
                props.library["variableData"][selectionTitle.toLowerCase()]
              );
        }

        while (!notStalePrompt(props, varData, categoryId)) {
          varData = getRandObj(
            props.library["variableData"][selectionTitle.toLowerCase()]
          );
        }
        if (typeof varData === "object") {
          votePrompt = eventSelection["prompt"] + varData["prompt"];
        } else {
          votePrompt = eventSelection["prompt"] + varData;
        }
      }
      if (voteSet === "players") {
        voteOptions = eventSelection.noRandom
          ? []
          : [{ id: "Random", name: "Random" }];
        for (const [id, player] of Object.entries(props.lobby["players"])) {
          if (player["connected"]) {
            voteOptions.push({
              id: id,
              name: player["name"],
            });
          }
        }
      } else if (voteSet === "var") {
        voteOptions = varData["voteSet"];
      }
    }
    if (selectionDeckExp && selectionDeckExp.includes("e")) {
      //Electing
      groupElect = true;
    }
    let ruleEvent = null;
    if (categoryId == "7") {
      let fakeTitle = "";
      if (eventSelection["fakeTitle"]) {
        let turnPlayerName = getMyPlayer(props).name;
        if (turnPlayerName.length > 8) {
          turnPlayerName = turnPlayerName.slice(0, 7) + "..";
        }
        fakeTitle = eventSelection["fakeTitle"].replace(
          /<name>/g,
          turnPlayerName
        );
      }
      ruleEvent = {
        rule: fakeTitle ? fakeTitle : selectionTitle,
        expires: props.lobby.turnNum + eventSelection["expires"],
      };
    }

    let payload = { ...eventSelection };
    payload["selectionTitle"] = selectionTitle;
    payload["selectionRules"] = selectionRules;
    payload["eventOptions"] = eventOptions;
    payload["categoryId"] = categoryId;
    payload["varData"] = varData;
    payload["voteOptions"] = voteOptions;
    payload["votePrompt"] = votePrompt;
    payload["ruleEvent"] = ruleEvent;
    if (groupElect) {
      payload["groupElect"] = groupElect;
    }
    return payload;
  } else {
    console.log("CATEGORY " + categoryId);
  }
}
